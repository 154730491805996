import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Stack } from "@mui/material";
import moment from "moment-timezone";
import { Fragment, useContext, useEffect, useState } from "react";
import Chart from "../../components/Charts";
import { connect } from "react-redux";
import _ from "lodash";
import { FirewallReportContext, updateActivitySelection } from "./FirewallReportContext";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { getMinutes, getTimezoneCity } from "../../utils/util";



const DefaultMarker = {
    symbol: 'circle'
};

const ChartColors = ["#3C7DD9", "#8cb5ec"];

const ChartLabels = ['Allowed', 'Denied'];

const options: Highcharts.Options = {
    chart: {
        type: 'spline',
        height: '210px',
        zooming: {
            type: 'x', // Updated property
        },
        plotBorderWidth: 1,
        resetZoomButton: {
            theme: {
                style: {
                    display: 'none'
                }
            }
        },
        style: {
            fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
        },
    },
    colors: ChartColors,
    title: {
        text: '',
        align: 'left',
        floating: true,
        x: 12,
        y: 32,
        style: {
            fontWeight: '500'
        }
    },
    lang: {
        noData: "No Data",
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        },
    },
    credits: {
        enabled: false
    },
    legend: {
        enabled: false
    },
    tooltip: {
        valueSuffix: '',
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        valueDecimals: 0,
        borderWidth: 1
    },
    xAxis: {
        gridLineWidth: 0.5,
        reversed: false,
        type: "datetime",
        maxPadding: 0.05,
        showLastLabel: true
    },
    yAxis: {
        title: {
            text: 'No of packets'
        }
    },
    series: new Array<any>()
}

interface LineChartData {
    tb: string;
    action: number;
    total: number;
    groupHelper: string;
}

const FirewallReportLineChart = (props) => {

    const { getFirewallLineChart, getVesselsListing, newSummaryStartDate, newSummaryEndDate, userTimezone } = props;

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

    const { selectedStartDate, selectedEndDate, startDate, endDate, onPinch, resetZoom, zoomOut, currentZoom, activities, handleActivityFilter, onSelectFilters } = useContext(FirewallReportContext);
    const { handleBinChange, selectedBin } = useContext(FirewallReportContext);
    const timeZone = getTimezoneCity(userTimezone);

    const getDateForChart = (data) => {
        if(data.length > 1) {
           return false;
        }
        return true;
    }

    useEffect(() => {
        if (_.isEmpty(getFirewallLineChart))
            return;
        const data = getFirewallLineChart?.hasOwnProperty('data') ? getFirewallLineChart?.data : {};
        if (!data)
            return;
        const columns: string[] = data.columns ?? [];
        const rows: any[] = data.rows ?? [];
        const allowed: Highcharts.PointOptionsObject[] = [];
        const denied: Highcharts.PointOptionsObject[] = [];

        rows.forEach((row) => {
            const item: Partial<LineChartData> = {};
            columns.forEach((col, index) => {
                item[col] = row[index];
            })
            const { action = 0, tb = '', total = 0 } = item;
            const value = { x: moment.utc(tb).valueOf(), y: total };
            if (action == 0) {
                denied.push(value);
            } else {
                allowed.push(value)
            }
        });
        const { xAxis, chart } = options;
        const currentOptions: Highcharts.Options = {
            ...options,
            time: {
                timezone: timeZone
            },
            chart: {
                ...chart,
                events: {
                    selection: onChartSelect,
                },
            },
            xAxis: {
                ...xAxis,
                labels: {
                    format: getDateForChart(allowed) && getDateForChart(denied) ? '{value:%e-%b %l:%M}' : ''
                }
            },
            series: [
                {
                    type: 'spline',
                    name: 'Allowed',
                    data: allowed,
                    marker: DefaultMarker,
                    visible: true
                },
                {
                    type: 'spline',
                    name: 'Denied',
                    data: denied,
                    marker: DefaultMarker,
                    visible: true
                }
            ]
        };
        setChartOptions(currentOptions)
    }, [getFirewallLineChart, userTimezone])

    useEffect(() => {
        if (_.isEmpty(getVesselsListing)) {
            setChartOptions(options);
        }
    }, [getVesselsListing])

    const onChartSelect: Highcharts.ChartSelectionCallbackFunction = (value) => {
        if (typeof value.xAxis == 'undefined') {
            onPinch(selectedStartDate, selectedEndDate);
            return;
        }
        const { xAxis: [{ min, max }] } = value;
        const pinchDateStart = moment(min);
        const pinchEndDate = moment(max);
        onPinch(pinchDateStart, pinchEndDate);
        return false;
    }

    const handleResetZoom = () => {
        resetZoom();
    }

    const handleStepwiseResetZoom = () => {
        zoomOut();
    }

    const handleActivityLinkClick = (data: string) => {
        let selectedActivity = updateActivitySelection(activities, data);
        if (!selectedActivity) return;
        handleActivityFilter(selectedActivity);
        onSelectFilters({ filterActivities: activities });
    }

    return (
        <Grid className="margin-t-15 firewalLineChartContainer">

            <div className="filter-reset-pos">
                <Grid className="pieChat-selectTag" id={currentZoom.length > 0 ? 'select-bin-remove-margin' : 'select-bin-add-margin'}>
                    <FormControl variant="standard" className='selectEntry-pie'>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedBin}
                            onChange={handleBinChange}
                        >
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5m'}>5m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10m'}>10m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15m'}>15m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1h'}>1h</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 240 && <MenuItem value={'4h'}>4h</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6h'}>6h</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12h'}>12h</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1d'}>1d</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7d'}>7d</MenuItem>}
                        </Select>
                    </FormControl>
                </Grid>
                {currentZoom.length > 0 && <Fragment>
                    <FormControlLabel
                        value="Zoom Back"
                        control={<IconButton onClick={handleStepwiseResetZoom}><ZoomOutIcon /></IconButton>}
                        label="Zoom Back"
                        labelPlacement="start" />
                    <FormControlLabel
                        value="Zoom Reset"
                        control={<IconButton onClick={handleResetZoom}><RestartAltIcon /></IconButton>}
                        label="Zoom Reset"
                        labelPlacement="start" />
                </Fragment>}
            </div>
            <Chart chartOptions={chartOptions} hideLegendTable={true} title="Firewall" />
            <Stack justifyContent='center' spacing={2} justifyItems='center' direction='row'>
                {ChartLabels.map((item, i) => <Stack alignItems='center' spacing={1} key={`firewall-LineChartLegend-${i}`} direction='row'>
                    <div style={{ background: `${ChartColors[i]}` }} className="usageDot-1"></div>
                    <Grid className="graph-totalUsageTitle"><div className="cardFilter line-cardFilter" onClick={() => handleActivityLinkClick(item)}>{item}</div></Grid>
                </Stack>)}
            </Stack>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getFirewallLineChart: state.authReducer?.getFirewallLineChart,
    getVesselsListing: state.authReducer?.getVesselsListing,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    userTimezone: state?.authReducer?.userTimezone
});

export default connect(mapStateToProps)(FirewallReportLineChart);