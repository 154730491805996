import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Grid, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Pagination } from "../../components/Pagination";
import { getDecodeURI, getEncodedURI } from "../../utils/util";
import { getHubDataTableRow, getHubDataTableRowCount } from '../../actions/Users/authenticateHub';
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import _ from "lodash";
import Row from "./Row";

const HubDetailsTable = (props) => {

    const { selectedOu, startDate, endDate, getHubVesselListing, hubFilteredVessels, getHubDataTableRow, getHubDataTableRowCount, hubDataTableRow, setHubDataLoading, hubDataTableRowCount, location, history } = props;

    let _q = getDecodeURI(location?.search);
    const [tableData, setData] = useState([]);
    const [page, setPage] = useState(_q.hasOwnProperty('pageStart') ? _q.pageStart : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('pageLimit') ? _q.pageLimit : 10);
    const [count, setCount] = useState(0);
    const [selectedOrg, setSelectedOu] = useState<any>({});
    const [vessels, setVessels] = useState([]);
    const USAGE = 'totalUsage';
    const AVAILABILITY = 'availibility';
    const CLIENTS = 'clients';
    const THROUGHPUTY = 'throughput';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : USAGE);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [interval, setInterval] = useState(_q.hasOwnProperty('interval') && _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h');
    const ENTER_KEY_CODE = "Enter";
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const searchTimeoutRef = useRef<any>();

    useEffect(() => {
        if (!_.isEmpty(selectedOu) && selectedOu?.id) {
            setSelectedOu(selectedOu)
        }
    }, [selectedOu])

    useEffect(() => {
        if (_.isEmpty(getHubVesselListing))
            return;
        let Vessels = [];
        const filteredVessels = hubFilteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = getHubVesselListing?.data;
        }
        setVessels(Vessels);
    }, [getHubVesselListing, hubFilteredVessels])

    useEffect(() => {
        const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
        setInterval(_interval);
        if (!_.isEmpty(vessels) && selectedOrg?.id) {
            getHubDataTableRow(selectedOrg.id, startDate, endDate, 1, limit, _interval, vessels, sort, sortOrder, searchValue);
            getHubDataTableRowCount(selectedOrg.id, vessels, searchValue);
        }
        setPage(1)
    }, [vessels, startDate, endDate])

    useEffect(() => {
        if (!_.isEmpty(hubDataTableRow)) {
            const data = hubDataTableRow.hasOwnProperty('data') ? hubDataTableRow.data : {};
            if (!_.isEmpty(data)) {
                let resultArr: any = Object.keys(data)?.map((row) => {
                    let mainObj: any = {};
                    let eachRowArr: any = [];
                    data[row]?.rows?.forEach((innerRow, i) => {
                        if (i == 0) {
                            data[row]?.columns.forEach((item, j) => {
                                mainObj[item] = innerRow[j]
                            })
                        } else {
                            let innerObj: any = {}
                            data[row]?.columns.forEach((item, j) => {
                                innerObj[item] = innerRow[j]
                            })
                            eachRowArr.push(innerObj)
                        }
                    })
                    mainObj.eachRow = eachRowArr;
                    return mainObj
                })
                setData(resultArr);
            } else {
                setData([]);
            }
        }
        if (!_.isEmpty(hubDataTableRowCount)) {
            const count = hubDataTableRowCount.hasOwnProperty('data') ? hubDataTableRowCount.data : 0;
            setCount(count?.rows?.[0]?.[0])
        }
    }, [hubDataTableRow, hubDataTableRowCount])

    const rowsOption = [5, 10, 15, 25, 50, 100];

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.pageStart : params.pageStart = value;
        setPage(value);
        doNavigate(params);
        getHubDataTableRow(selectedOrg?.id, startDate, endDate, value, limit, interval, vessels, sort, sortOrder, searchValue);
    };

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.pageStart;
        Limit == 10 ? delete params.pageLimit : params.pageLimit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getHubDataTableRow(selectedOrg?.id, startDate, endDate, Page, Limit, interval, vessels, sort, sortOrder, searchValue);
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        vessels.length > 0 && getHubDataTableRow(selectedOrg.id, startDate, endDate, Page, limit, interval, vessels, field, order, searchValue);
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        doNavigate(params);
        setPage(1);
        getHubDataTableRow(selectedOrg?.id, startDate, endDate, 1, limit, interval, vessels, sort, sortOrder, value);
        getHubDataTableRowCount(selectedOrg.id, vessels, value);
      };
    
      const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
          doSearch(searchValue);
        }
      }

      const handleOnSearchClick = () => {
        doSearch(searchValue);
      }
    
      const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
      }
    
      const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
          clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
          searchTimeoutRef.current = undefined;
          doSearch(e.target.value);
        }, 1000)
      }

    return (
        <div className="hub-main-table-container">
            <Grid item xs={12} sm={12} md={12} lg={12} className="alignTopbarItemsCenter hub-table-search-switch">
                <Box className="searchbarBox">
                    {<TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Type something"
                        size="small"
                        value={searchValue}
                        onChange={handleOnSearchChange}
                        onKeyDown={(event) => searchBoxKeyDown(event)}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                            className: "searchbarInputs",
                            startAdornment: (
                                <InputAdornment position="start">
                                    {!searchValue && <SearchIcon
                                        className="searchbarIconsPosition"
                                        onClick={handleOnSearchClick}
                                    />}
                                    {searchValue && <CloseIcon
                                        className="searchbarIconsPosition"
                                        onClick={handleOnSearchClear}
                                    />}
                                </InputAdornment>
                            ),
                        }}
                    />}
                </Box>
            </Grid>
            <div>
                <Pagination
                    rowsPerPageOptions={rowsOption}
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Paper className="audit-container-data hub-container-data">
                    <TableContainer className="audit-table-root hub-table-container" component={Paper} style={{ maxHeight: 'calc(100vh - 270px)', overflow: 'auto' }}>
                        <Table size="small" aria-label="collapsible sticky table" stickyHeader style={{tableLayout: 'fixed'}}>
                            <TableHead className="audit-data-head hub-table-header">
                                <TableRow>
                                    <TableCell className="background-header-cell arrow-column" />
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">SITE / AGENT</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">ORGANIZATION</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">LOCATION</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">DEVICE / AGENT</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-usage">
                                        {/* <span style={{ whiteSpace: "nowrap" }} className="align-items-center"> */}
                                            <span onClick={(event) => switchSorting(event, USAGE)} className="hub-sorting-columns">USAGE(GB)</span>
                                            {/* <Grid className="sort-icon-margin">
                                                <img src={sort === USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USAGE, ASC_ORDER)} />
                                                <img src={sort === USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span> */}
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-mpk-status">
                                        {/* <span style={{ whiteSpace: "nowrap" }} className="align-items-center"> */}
                                            <Tooltip title='Availability'><span onClick={(event) => switchSorting(event, AVAILABILITY)} className="hub-sorting-columns">AVAIL(%)</span></Tooltip>
                                            {/* <Grid className="sort-icon-margin">
                                                <img src={sort === AVAILABILITY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, AVAILABILITY, ASC_ORDER)} />
                                                <img src={sort === AVAILABILITY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, AVAILABILITY, DESC_ORDER)} />
                                            </Grid>
                                        </span> */}
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-throughput">
                                        <Tooltip title={'(Mbps)'}>
                                            {/* <span className="align-items-center"> */}
                                                <span onClick={(event) => switchSorting(event, THROUGHPUTY)} className="hub-sorting-columns">THROUGHPUT (Mbps)</span>
                                                {/* <Grid className="sort-icon-margin">
                                                    <img src={sort === THROUGHPUTY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, THROUGHPUTY, ASC_ORDER)} />
                                                    <img src={sort === THROUGHPUTY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, THROUGHPUTY, DESC_ORDER)} />
                                                </Grid>
                                            </span> */}
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-mpk-status">
                                        {/* <span style={{ whiteSpace: "nowrap" }} className="align-items-center"> */}
                                            <span onClick={(event) => switchSorting(event, CLIENTS)} className="hub-sorting-columns">CLIENTS</span>
                                            {/* <Grid className="sort-icon-margin">
                                                <img src={sort === CLIENTS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CLIENTS, ASC_ORDER)} />
                                                <img src={sort === CLIENTS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CLIENTS, DESC_ORDER)} />
                                            </Grid>
                                        </span> */}
                                    </TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell hub-width-internet-status">INTERNET STATUS</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell hub-width-mpk-status">MPK/VPN STATUS</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell hub-width-internet-status">TUNNELS CONNECTED</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell hub-width-portal">PORTAL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="audit-data-header audit-logs-font">
                                {!setHubDataLoading && tableData?.length > 0 ? tableData?.map((row, i) => (
                                    <Row row={row} index={i} />
                                )) : <TableRow>
                                    <TableCell colSpan={12} align='center' className="audit-logs-no-data">
                                        No Data Available
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer?.selectedOu,
    hubDataTableRow: state.authReducer.hubDataTableRow,
    setHubDataLoading: state.authReducer.setHubDataLoading,
    hubDataTableRowCount: state.authReducer.hubDataTableRowCount,
    selectedOrg: state.authReducer.selectedOrg,
    startDate: state.authReducer.newSummaryStartDate,
    endDate: state.authReducer.newSummaryEndDate,
    getHubVesselListing: state.authReducer.getHubVesselListing,
    hubFilteredVessels: state.authReducer.hubFilteredVessels,
});

export default withRouter(
    connect(mapStateToProps, { getHubDataTableRow, getHubDataTableRowCount })(HubDetailsTable)
);