import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef, Fragment } from "react";
import { getCaptiveDashPlanTable } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { getDecodeURI, getEncodedURI, readableBytesAsGB } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { FiberManualRecord } from "@mui/icons-material";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[1], row[6], row[4], row[2], row[7], readableBytesAsGB(row[3]), row[5]])
    })
    return [['Plan Name', 'Organization', 'Active Sites', 'Subscribers Count', 'TopUp Count', 'Plan Quota (GB)', 'Avg Usage (GB)'], ...csvRows]
}

const PlansTable = (props) => {
    const { getCaptiveDashPlanTable, vessels, newSummaryStartDate, newSummaryEndDate, getCDEventsSubscribersPlanUsageTable, setCaptiveDashEventsSubscribersPlanUsageTableLoading, csvDataDownloded, csvDownloadId, downloadAsCSV, location, history } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const PLAN_NAME = 'planName';
    const ORG_NAME = 'organization';
    const ACTIVE_SITES = 'activeSites';
    const SUBSCRIBERS = 'subscribersCount';
    const AVERAGE_USAGE = 'avgQuotaUsed';
    const TOPUP_COUNT = 'topUps';
    const PLAN_QUOTA = 'planQuota';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : SUBSCRIBERS);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (vessels?.length >= 1) {
            getCaptiveDashPlanTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, limit, sort, sortOrder, searchValue)
        } else {
            setTableData([]);
            setCount(0);
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if (!_.isEmpty(getCDEventsSubscribersPlanUsageTable)) {
            const data = getCDEventsSubscribersPlanUsageTable.hasOwnProperty('data') ? getCDEventsSubscribersPlanUsageTable.data : [];
            setCount(data?.[0]?.['count']);
            setTableData(data);
        } else{
            setTableData([]);
        }
    }, [getCDEventsSubscribersPlanUsageTable])

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'captive-dashboard-plans_table') {
                const [columns, _countRow, ...rows] = csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters: {
                        0: 'ESC-COMMA',
                        1: 'ESC-COMMA',
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        vessels && getCaptiveDashPlanTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, sort, sortOrder, value)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        vessels && getCaptiveDashPlanTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, field, order, searchValue)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value);
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.page : params.page = value;
        doNavigate(params);
        vessels && getCaptiveDashPlanTable(vessels, newSummaryStartDate, newSummaryEndDate, value, limit, sort, sortOrder, searchValue)
    }

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        vessels && getCaptiveDashPlanTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, Limit, sort, sortOrder, searchValue)
    }

    const handleDownloadReport = () => {
        let isClickHouse = true;
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const params: CsvParameters = {
            type: isClickHouse ? 'QUERY_CH' : "QUERY",
            id: "captive-dashboard-plans_table",
            queryName: 'CAPTIVE_DASHBOARD_PLANS_TABLE_V2',
            payload: {
                k4Id: vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                startDate: startDate,
                endDate: endDate,
                search: searchValue,
                pageOffset: 0,
                pageSize: count,
                sortColumn: sort,
                sortOrder: sortOrder
            }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.k4Ids = `${row.k4Id}:${row.siteName}`;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    };

    const handleOrgClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.ouName = `${row.organization}`;
        params.ouId = `${row.dpId}`;
        delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    };

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={3} sm={3} md={3} lg={3} className="site-details-header usage-site-details-header">Subscriber Plan Usage</Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9} className="usage-table-search-download-div">
                        <Grid className="usage-search-border-color">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_NAME)}>Plan</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_NAME, ASC_ORDER)} />
                                                <img src={sort === PLAN_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, PLAN_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ORG_NAME)}>Organization</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ORG_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ORG_NAME, ASC_ORDER)} />
                                                <img src={sort === ORG_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, ORG_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ACTIVE_SITES)}>Active Sites</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ACTIVE_SITES && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ACTIVE_SITES, ASC_ORDER)} />
                                                <img src={sort === ACTIVE_SITES && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, ACTIVE_SITES, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SUBSCRIBERS)}>Subscriber Count</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SUBSCRIBERS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SUBSCRIBERS, ASC_ORDER)} />
                                                <img src={sort === SUBSCRIBERS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SUBSCRIBERS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOPUP_COUNT)}>TopUp Count</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TOPUP_COUNT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOPUP_COUNT, ASC_ORDER)} />
                                                <img src={sort === TOPUP_COUNT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, TOPUP_COUNT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_QUOTA)}>Plan Quota (GB)</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_QUOTA && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_QUOTA, ASC_ORDER)} />
                                                <img src={sort === PLAN_QUOTA && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, PLAN_QUOTA, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, AVERAGE_USAGE)}>Avg Usage</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === AVERAGE_USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, AVERAGE_USAGE, ASC_ORDER)} />
                                                <img src={sort === AVERAGE_USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, AVERAGE_USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {count > 0 && tableData && 1 != tableData?.length && !setCaptiveDashEventsSubscribersPlanUsageTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData?.map((item, i) => (
                                            i > 0 && <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <Tooltip title={item.planName}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.planName}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item.organization}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleOrgClick(item)}>{item?.organization}</TableCell>
                                                </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.activeSites}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.subscribersCount}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.topUps}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{readableBytesAsGB(item?.planQuota)}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{`${item?.avgQuotaUsed.toFixed(2)} GB`}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}


const mapStateToProps = (state) => ({
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    getCDEventsSubscribersPlanUsageTable: state?.authReducer?.getCDEventsSubscribersPlanUsageTable,
    setCaptiveDashEventsSubscribersPlanUsageTableLoading: state?.authReducer?.setCaptiveDashEventsSubscribersPlanUsageTableLoading,
    csvDataDownloded: state?.authReducer?.csvDataDownloded,
    csvDownloadId: state?.authReducer?.csvDownloadId
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashPlanTable, downloadAsCSV })(PlansTable)
);