import { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, updateTimePeriod } from "../../utils/util";
import { SIDE_MENU_ITEM_CAPTIVE } from "../../utils/constants";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters"
import NotAuthorized from "../NotAuthorized"
import { updatePermission } from "../../actions/Users/authenticate";
import _ from "lodash";
import TabsComponent from "../../components/NewTabs";
import CaptiveTopWidget from './captiveComponent/topWidget';
import CaptiveCharts from './captiveComponent';
import Table from './captiveComponent/Table';
import SubscriberTable from './captiveComponent/SubscriberTable';
import PlansCharts from './captivePlans/LineChart';
import PlansTable from './captivePlans/PlansTable';
// import ChipFilters from './captiveComponent/ChipFilters';
// import FilterPopup from './captiveComponent/FilterPopup';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, Grid, Menu } from "@mui/material";
import { getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashUsageLineChart, getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashSitesTable, getCaptiveDashSubscribersSessionsTable, getCaptiveDashTopPlansUsersPie, getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlanTable } from "../../actions/Users/authenticateCaptiveDahshboard";

const CaptiveDashboard = (props) => {
  const { authReducer, newSummaryStartDate, newSummaryEndDate, updatePermission, history, location, getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashUsageLineChart, getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashSitesTable, getCaptiveDashSubscribersSessionsTable, getCaptiveDashTopPlansUsersPie, getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlanTable } = props;

  const _q = getDecodeURI(location?.search);
  const [selectedTab, setSelectedTab] = useState(_q.hasOwnProperty('tab') ? _q.tab : "sites");
  const [loading, setLoading] = useState();
  const [vessels, setVessels] = useState<any>();
  const [topcardSelected, setTopCardSelected] = useState(_q.hasOwnProperty('topCard') ? _q.topCard : '')
  const dispatch = useDispatch();
  const [filterPopup, setFilterPopup] = useState(false);
  const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();
  const getInterval = (minutes) => {
    if (minutes <= 720) {
        return "5 minutes";
    } else if (minutes > 720 && minutes <= 1440) {
        return "15 minutes";
    } else if (minutes > 1440 && minutes <= 10080) {
        return "1 hour";
    } else {
        return "12 hour";
    }
}
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  const not_authorized_page_title = "Dashboard";
  const not_authorized_page_message = "You are not authorised to view site dashboard.";

  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

  const [selectedSubscribers, setSelectedSubscribers] = useState<any>(_q.hasOwnProperty('subscribers') ? _q.subscribers.split(',') : []);
  const [selectedPlans, setSelectedPlans] = useState<any>(_q.hasOwnProperty('selectedPlans') ? _q.selectedPlans.split(',') : []);
  const values = { selectedSubscribers, selectedPlans };
  const setValues = { setSelectedSubscribers, setSelectedPlans };

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_CAPTIVE !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_CAPTIVE };
  }

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
    setIsAuthorizedToViewPage(authorized);
    const info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };

    if (authorized) {
      info.isMultiVessel = true;
      info.showOu = true;
    }

    updatePermission(info);

    const params = getDecodeURI(location?.search);
    setTopCardSelected(!params.topCard ? 'TOTAL USAGE' : params.topCard)
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    !params.topCard ? params.topCard = 'TOTAL USAGE' : params.topCard = params.topCard;
    history.push({ pathname: location.pathname, search: getEncodedURI(params) });
  }, []);

  useEffect(() => {
    setLoading(authReducer.setCaptiveDashSitesSubscribersSesTopWidgetLoading || authReducer.setCaptiveDashPlansTopWidgetLoading || authReducer.setCaptiveDashUsageTopWidgetLoading ||
      authReducer.setCaptiveDashSitesUsageBarLoading || authReducer.setCaptiveDashSubscribersLineChartLoading ||
      authReducer.setCaptiveDashSessionsLineChartLoading || authReducer.setCaptiveDashUsageLineChartLoading || authReducer.setCaptiveDashSitesTableLoading ||
      authReducer.setCaptiveDashTopSitesSubscribersBarLoading || authReducer.setCaptiveDashTopSitesSessionsBarLoading ||
      authReducer.setCaptiveDashSubscribersSessionsTableLoading || authReducer.setCaptiveDashTopPlansUsersPieLoading ||
      authReducer.setCaptiveDashEventsSubscribersPlanUsageTableLoading ||
      authReducer.setCaptiveDashTopSubscribersUsageBarLoading || authReducer.setCaptiveDashTopSubscribersSessionsBarLoading || authReducer.setCaptiveDashPlansPercentageUsageCountBarLoading ||
      authReducer.setCaptiveDashPlansUsageLineChartLoading || authReducer.setCaptiveDashSubscribersListLoading || authReducer.setCaptiveDashPlansListLoading)
  }, [authReducer.setCaptiveDashSitesSubscribersSesTopWidgetLoading, authReducer.setCaptiveDashPlansTopWidgetLoading, authReducer.setCaptiveDashUsageTopWidgetLoading,
  authReducer.setCaptiveDashSitesUsageBarLoading, authReducer.setCaptiveDashSubscribersLineChartLoading, authReducer.setCaptiveDashSessionsLineChartLoading,
  authReducer.setCaptiveDashUsageLineChartLoading, authReducer.setCaptiveDashSitesTableLoading, authReducer.setCaptiveDashTopSitesSubscribersBarLoading, authReducer.setCaptiveDashTopSitesSessionsBarLoading,
  authReducer.setCaptiveDashSubscribersSessionsTableLoading, authReducer.setCaptiveDashTopPlansUsersPieLoading,
  authReducer.setCaptiveDashEventsSubscribersPlanUsageTableLoading, authReducer.setCaptiveDashTopSubscribersUsageBarLoading,
  authReducer.setCaptiveDashTopSubscribersSessionsBarLoading, authReducer.setCaptiveDashPlansPercentageUsageCountBarLoading, authReducer.setCaptiveDashPlansUsageLineChartLoading, authReducer.setCaptiveDashSubscribersListLoading, authReducer.setCaptiveDashPlansListLoading])

  const tabs = [
    { tabName: 'Sites', tabValue: 'sites', pathName: '/captive' },
    { tabName: 'Subscribers', tabValue: 'subscribers', pathName: '/captive' },
    { tabName: 'Plans', tabValue: 'plans', pathName: '/captive' },
    // { tabName: 'Admin', tabValue: 'admin', pathName: '/captive' }
  ]

  const doNavigateTab = (pathname: any, tabName: any) => {
    const currentParams = getDecodeURI(location?.search);
    setSelectedTab(tabName);
    setTopCardSelected('TOTAL USAGE');
    setSelectedSubscribers([]);
    setSelectedPlans([]);
    currentParams.tab = tabName;
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    currentParams.startDate = startDate;
    currentParams.endDate = endDate;
    delete currentParams.page;
    delete currentParams.limit;
    delete currentParams.sort;
    delete currentParams.sortOrder;
    currentParams.topCard = 'TOTAL USAGE';
    delete currentParams.subscribers;
    delete currentParams.selectedPlans;
    history.push({ pathname, search: getEncodedURI(currentParams) });
  }

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
      return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
      vessels = filteredVessels?.filter((el) => el?.id !== "");
    } else {
      vessels = authReducer?.getVesselsListing?.locations;
    }
    setVessels(vessels);
  }, [authReducer?.getVesselsListing, authReducer.filteredVessels])

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }

  const handleClickFilterPopup = () => {
    setFilterPopup(true);
  }

  const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElRef(e.currentTarget)
    handleClickFilterPopup();
  }

  const applyFilterClick = (subscribers, plans) => {
    let params: any = getDecodeURI(location.search);
    delete params.page;
    !_.isEmpty(subscribers) ? params.subscribers = subscribers?.join(',') : delete params.subscribers;
    !_.isEmpty(plans) ? params.selectedPlans = plans?.join(',') : delete params.selectedPlans;
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    const _interval = getInterval(
      Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)
    )
    const sortColumn = _q.hasOwnProperty('sort') ? _q.sort : 'subscriberCount';
    const sortOrder = _q.hasOwnProperty('sortOrder') ? _q.sortOrder : 'Desc';
    const tabToChartMap = {
      'sites': {
        'SUBSCRIBERS': () => getCaptiveDashTopSitesSubscribersBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers),
        'SESSIONS': () => getCaptiveDashTopSitesSessionsBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers),
        'TOTAL USAGE': () => getCaptiveDashTopSitesUsageBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers),
      },
      'subscribers': {
        'TOTAL USAGE': () => getCaptiveDashTopSubscribersUsageBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers, plans),
        'SESSIONS': () => getCaptiveDashTopSubscribersSessionsBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, 1, subscribers, plans)
      },
      'plans': {
        'TOTAL USAGE': () => getCaptiveDashTopPlansUsersPie(vessels, newSummaryStartDate, newSummaryEndDate, 10, plans)
      }
    };
    tabToChartMap[_q.tab]?.[_q.topCard]?.();

    const tabToLineChartMap = {
      'sites': {
        'SUBSCRIBERS': () => getCaptiveDashSubscribersLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
        'SESSIONS': () => getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
        'TOTAL USAGE': () => getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers),
      },
      'subscribers': {
        'SESSIONS': () => getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers, plans),
        'TOTAL USAGE': () => getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, subscribers),
      },
      'plans': {
        'TOTAL USAGE': () => getCaptiveDashPlansPercentageUsageCountBar(vessels, newSummaryStartDate, newSummaryEndDate, plans)
      }
    };
    tabToLineChartMap[_q.tab]?.[_q.topCard]?.();

    const tabToTableMap = {
      'sites': () => getCaptiveDashSitesTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, 15, sortColumn, sortOrder, '', subscribers),
      'subscribers': () => getCaptiveDashSubscribersSessionsTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, 15, 'totalSessions', sortOrder, '', subscribers, plans),
      'plans': () => getCaptiveDashPlanTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, 15, '', 'desc', '', plans),
    };
    tabToTableMap[_q.tab]?.();
  }

  return (
    <div className="Form">
      <CommonFilters loading={loading} showWantType={false}></CommonFilters>
      <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedTab} />
      <Grid key="dashboard" container style={{ backgroundColor: "#ffffff" }}>
        <Grid key="fleet" item className="threat-dashboard-NewSummary">
          {
            isAuthorizedToViewPage ? [
              <Grid className="new-summary-dash-container">
                {/* <Box className='activityReports-Filter alignTopbarItemsCenter'>
                  <Fragment>
                    <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5} className="filter-dateRange-container captive-dashboard-filters-container">
                      <Button variant="outlined" startIcon={<TuneIcon id="filterIcon" />} onClick={filterPopClick} id="filterButton">
                        Filter
                      </Button>
                      <ChipFilters />
                    </Grid>
                    <Menu disableEnforceFocus
                      onClose={() => setFilterPopup(false)}
                      open={filterPopup}
                      className="rc--filter_menu_container_reports"
                      anchorEl={anchorElRef}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Grid className="usageFilterComponent">
                        <FilterPopup vessels={vessels} filterPopup={filterPopup} setFilterPopup={setFilterPopup} values={values} setValues={setValues} applyFilterClick={applyFilterClick} />
                      </Grid>
                    </Menu>
                  </Fragment>
                </Box> */}
                <Grid key="SubHeader" className="SubHeader_NewSummary padding-right-17">
                  <CaptiveTopWidget vessels={vessels} topcardSelected={topcardSelected} setTopCardSelected={setTopCardSelected} selectedTab={selectedTab} />
                  {selectedTab == 'sites' || selectedTab == 'subscribers' ? <CaptiveCharts vessels={vessels} topcardSelected={topcardSelected} selectedTab={selectedTab} values={values} /> : <PlansCharts vessels={vessels} values={values} />}
                  {selectedTab == 'subscribers' ? <SubscriberTable vessels={vessels} values={values} setValues={setValues} applyFilterClick={applyFilterClick} /> : selectedTab == 'sites' ? <Table vessels={vessels} values={values} /> : <PlansTable vessels={vessels} values={values} />}
                </Grid>
              </Grid>
            ] :
              <Grid key="noitems" item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10, paddingTop: "80px" }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
});

export default withRouter(
  connect(mapStateToProps, { updatePermission, getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashUsageLineChart, getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashSitesTable, getCaptiveDashSubscribersSessionsTable, getCaptiveDashTopPlansUsersPie, getCaptiveDashPlansPercentageUsageCountBar, getCaptiveDashPlanTable })(CaptiveDashboard)
);