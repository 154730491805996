// Card Details for the summary page
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Grid, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Line from '../../asset/image/DividerLine.svg';
import Box from '@mui/material/Box';
import "./CardDetails.css";
import { connect, useDispatch } from 'react-redux';
import {  getTopWidgetDataNewSumm, getTopWidgetWanlinkStatusNewSumm, getNewSummaryUptimeAllLinks, getAlertTopWidget} from '../../actions/Users/authenticateTrends'
import { withRouter } from "react-router-dom";
import { readableBytes, getDecodeURI, getEncodedURI, readableBytesAsGB } from '../../utils/util';
import { displayToastError } from "../../server/request";
import _ from "lodash";
import {  GET_TOP_WIDGET_DATA_NEW_SUMM, GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM,UPDATE_NEW_SUMMARY_WAN_TYPE, GET_NEW_SUMMARY_UPTIME_ALL_LINK_AVAILABLITY } from "../../actions/types";
import { useHistory, useLocation } from 'react-router-dom';
import vsat_icon from "../../asset/image/vsat_icon.svg";
import Starlink_Icon from "../../asset/image/Starlink_Icon.svg";
import cellular_icon from "../../asset/image/cellular_icon.svg";

import availability_icon from "../../asset/image/availability.svg";
import security_icon from "../../asset/image/security.svg";
import system_icon from "../../asset/image/system.svg";
import { NEWINTERVALS, WAN_ARRAY } from "../../constants/Constants";
import { makeStyles } from "@material-ui/core/styles";
import SidebarNavUserIcon from '../../asset/image/SideNavUserIcon.svg';
import SideNavQuotaControl from '../../asset/image/SideNavQuotaControl.svg';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor:'#D6DAFA',
  },
  barColorPrimary: {
    backgroundColor:  '#2F4B82',
  }
});


const CardContents = (cardHeading, leftData, online, offline,selectedOu, img, data, tabValue) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }
  const handleCardClick = () => {
   dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: data})
   let params = getDecodeURI(location?.search);
    params.wanType = data?.label;
    delete params.page;
    doNavigate(location.url, params);
  }
  let params = getDecodeURI(location?.search);
  return (
    <Grid className="card-box-parent" item  xs={ tabValue === 1  ? 1.6 :2}>
     <Card className="card-widget_NewSummary flex_grow new-sum-cards-box-shadow" id={'addBoxShadow'} onClick={() => handleCardClick(cardHeading)}>
        <Box className="cardHeader cardHeaderTile_NewSummary ">
           {/* {!(!_.isEmpty(params) && params.hasOwnProperty("k4Ids")) && <img src={img} className="cardHeaderImage_NewSummary" alt="Card Image" /> } */}
            <Typography className="cardTitle_NewSummary">{cardHeading}</Typography>
          </Box>
          <div className={`${!_.isEmpty(params) && params.hasOwnProperty("k4Ids") ? "singleVesselCard" :""}`}>
            <Box className="cardContents_NewSummary cardNew">
                <Box className="cardLeftContent_NewSummary">
                  <Typography className="content_NewSummary">{leftData && leftData.toString().split(" ")[0]}</Typography>
                  <Typography className="content_bottom_ns"> {leftData && leftData.toString().split(" ")[1]}</Typography>
                </Box>
                <img className="divider" src={Line} alt="Divider" />
            </Box>
            <Box className="newSummaryProgress">   
              {
              !_.isEmpty(params) && params.hasOwnProperty("k4Ids") && !_.isNaN(online) && !_.isNaN(offline) ?
                 <div>
                 <span className="cardOnlineStatatus_ns">Online links</span>
                 <span className="cardOnlineStatatusData_ns">{online + " /" + " " + offline } </span>
               </div>
                : 
                <>
                <LinearProgress  sx={{ "--LinearProgress-radius": "10px", borderRadius: "5px"}} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} variant="determinate" value={online / (online + offline) * 100} />
                <div className="cardOnlineStatatusWrapper_ns">
                  <div>
                    <span className="cardOnlineStatatus_ns">Online links</span>
                    <span className="cardOnlineStatatusData_ns">{online + " /" + " " + offline } </span>
                  </div>
                </div>
              </> 
              }
            </Box>
          </div>
        </Card>
      </Grid>
  );
}

const CardContentsWanType = (cardHeading, leftData, tabValue, rightData = null, ) => {
  return (
    <Grid item className="card-box-parent"  xs={ tabValue === 1 ? 2.2 :3}>
        <Card className="card-widget_NewSummary" id={'addBoxShadow'} >
        <Box className="cardHeader cardHeaderTile_NewSummary ">
          <Typography className="cardTitle_NewSummary">{cardHeading}</Typography>
        </Box>
        <Box className="cardContents_NewSummary cardNew">
            <Box className="cardLeftContent_NewSummary">
              {rightData !== null ?
                <Typography className="content_NewSummary"> {leftData+" /"+rightData}</Typography>
                :
                <>
                <Typography className="content_NewSummary">{leftData && leftData.toString().split(" ")[0]}</Typography>
                {leftData && leftData?.toString().split(" ").length > 1 ? <Typography className="content_bottom_ns"> {leftData && leftData?.toString().split(" ")[1]}</Typography> : null}    
                </>
            }
            </Box>
            <img className="divider" src={Line} alt="Divider" />
        </Box>  
      </Card>
    </Grid>
  );
}

const AlertsWidgetCards = (cardHeading, img, data, tabValue, topWidget, newSummaryWanType, cardDetails) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  const handleCardClick = () => {
   dispatch({ type: UPDATE_NEW_SUMMARY_WAN_TYPE, payload: data})
   let params = getDecodeURI(location?.search);
    params.wanType = data?.label;
    delete params.page;
    doNavigate(location.url, params);
  }

  let params = getDecodeURI(location?.search);

  return (
    <Grid item className="card-box-parent" xs={ tabValue === 1  ? 2.2 : tabValue === 2 && newSummaryWanType?.label == 'All' ? 1.2 : 3}>
      <Card className="card-widget_NewSummary flex_grow new-sum-cards-box-shadow" id={(cardHeading!=='Quota' && cardHeading!=='Availability' && cardHeading!=='Security' && cardHeading!=='System') && 'addBoxShadow'} onClick={() => (cardHeading!=='Quota' && cardHeading!=='Availability' && cardHeading!=='Security' && cardHeading!=='System') && handleCardClick(cardHeading)}>
        <Box className="cardHeader cardHeaderTile_NewSummary alerts-heading-count-div">
          <Grid className="alerts-heading-container">
            {/* {cardHeading!=='Quota' ? <img src={img} className="cardHeaderImage_NewSummary" alt="Card Image" /> : 
             <div className="quota_admin_icons" style={cardHeading=='Quota' ? {background:'#EEEEEE'} : {background:'#F8F0E5'}}>
              <img src={img} className="cardHeaderImage_NewSummary" alt="Card Image" />
             </div>
            } */}
              <Typography className="cardTitle_NewSummary">{cardHeading}</Typography>
          </Grid>
          {newSummaryWanType?.label !== 'All' && <Grid className="alerts-count-widget">{topWidget?.total ? topWidget?.total : 0}</Grid>}
        </Box>
        {newSummaryWanType?.label == 'All' ? <Grid className="Alerts-topWidget-Card">
          {cardDetails ?
          <Tooltip title={<>
              <div>{`Internet Presence : ${cardDetails?.internetPresence?.total}`}</div>
              <div>{`Link Status : ${cardDetails?.linkStatus?.total}`}</div>
              <div>{`Link Availability : ${cardDetails?.linkAvailability?.total}`}</div>
            </>
            } placement="top"><span>{topWidget?.total ? topWidget?.total : 0}</span></Tooltip>
           : <span>{topWidget?.total ? topWidget?.total : 0}</span>}
        </Grid> :
        <Grid className="Alerts-topWidget-details">
          <Grid>
            <span className="cardOnlineStatatus_ns">Link Status</span><br/>
            <span className="cardOnlineStatatusData_ns">{topWidget?.['Link_Status'] ? topWidget?.['Link_Status'] : 0}</span>
          </Grid>
          <Grid>
            <span className="cardOnlineStatatus_ns">Link Availability</span><br/>
            <span className="cardOnlineStatatusData_ns">{topWidget?.['Link_Availability'] ? topWidget?.['Link_Availability'] : 0}</span>
          </Grid>
        </Grid>}
      </Card>
    </Grid>
  );
}

const SummaryCardDetails = (props) => {
  const {  search, authReducer, errorReducer, vesselList, selectedOu, getTopWidgetDataNewSumm, getTopWidgetWanlinkStatusNewSumm, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, getNewSummaryUptimeAllLinks, tabValue, alertStatus, alertTopWidget} = props;
  const [cardetails, setCardDetails] = useState({})
  const [topWidgetData, setTopWidgetData] = useState({})
  const [interval, setInterval] = useState(NEWINTERVALS && NEWINTERVALS[0]?.label)
  const [uptime, setUptime] = useState(0);
  const [uptimeData, setUptimeData] = useState({})
  const [alertsTopWidgetData, setAlertsTopWidgetData] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [filter, setFilter] = useState('');

  const dispatch = useDispatch()
  const location = useLocation();

  useEffect(() => {
    let _q = getDecodeURI(location?.search);

    if(_q?.interval){
      let selectedInterval
      NEWINTERVALS?.map(interval => {
        if (interval.value === _q?.interval) {
          selectedInterval = interval;
        }
      });
    }

  }, []);

  useEffect(() => {
    let tableData = authReducer?.topWidgetLinkStatus?.data?.rows.map((item) => {
      return authReducer?.topWidgetLinkStatus?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })
    if (tableData && tableData.length > 0) {
      setCardDetails(tableData[0])
    } else {
      setCardDetails({})
    }
  }, [authReducer.topWidgetLinkStatus])

  useEffect(()=>{
    if(!_.isEmpty(authReducer?.newSummaryUptimeAllLinks)){
      let uptimeData = authReducer?.newSummaryUptimeAllLinks?.data?.rows.map((item) => {
        return authReducer?.newSummaryUptimeAllLinks?.data?.columns.reduce((vsd, columnitem, i) => {
          vsd[columnitem] = item[i]
          return vsd;
        }, {})
      })

      setUptimeData(uptimeData)

      if(uptimeData && uptimeData.length > 0){
        if(newSummaryWanType?.label === "All"){
          setUptime(uptimeData[0]["TOTAL_AVAIL"]?.toFixed(2))
        }else if(newSummaryWanType?.label === "VSAT"){
          setUptime(uptimeData[0]["VSAT"]?.toFixed(2))
        }else if(newSummaryWanType?.label === "CELLULAR"){
          setUptime(uptimeData[0]["LTE"]?.toFixed(2))
        }else if(newSummaryWanType?.label === "STARLINK"){
          setUptime(uptimeData[0]["starlink"]?.toFixed(2))
        }else if(newSummaryWanType?.label === "LBAND"){
          setUptime(uptimeData[0]["FBB"]?.toFixed(2))
        }else{
          setUptime(0)
        }
      }

    }

  },[authReducer?.newSummaryUptimeAllLinks])


  useEffect(() => {
    let tableData = authReducer?.setTopWidgetData?.data?.rows.map((item) => {
      return authReducer?.setTopWidgetData?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })
    if (tableData && tableData.length > 0) {
      setTopWidgetData(tableData[0])
    } else {
      setTopWidgetData({})
    }
  },[authReducer?.setTopWidgetData])

  useEffect(() => {
    if(!_.isEmpty(alertTopWidget)) {
        const data = alertTopWidget?.hasOwnProperty('data') ? alertTopWidget?.data : {};
        let finalData = {};
        let total = 0;
        for (let key in data) {
            let obj = { wanType: key, total: 0 }
            data[key]?.rows?.forEach((ele) => {
                obj.total += ele[2];
                obj[ele[1].split(' ').join('_')] = ele[2];
                if(key=='internetPresence' || key=='linkAvailability' || key=='linkStatus') {
                  total += ele[2]
                }
            })
            finalData[key] = obj;
        };
        finalData['total'] = {total};
        setAlertsTopWidgetData(finalData);
        authReducer.alertTopWidget = {};
      }
    }, [alertTopWidget])

  useEffect(() => {
    // create new requestAborter
    let _requestAbortController = new AbortController();
    
    if (interval && Object.keys(interval).length > 0 && !_.isEmpty(vesselList)) {
      let vessels = [];
      let params = getDecodeURI(location?.search);
      let filter= "";
      if(!_.isEmpty(params) && params.hasOwnProperty("filterApplied")){
        let statues = params?.internetStatus;
        if(statues){
          statues = statues.split(",")
          statues="'" + statues.join("','") + "'";
          filter = "AND status IN(" + statues+")"
        }
      }
      setFilter(filter);
      if (!_.isEmpty(authReducer?.selectedVessels?.vessels)) {
        vessels = authReducer?.selectedVessels?.vessels;

      } else if (!_.isEmpty(vesselList)) {
        vessels = vesselList?.locations;
        if(_.isEmpty(vessels)){
          dispatch({type: GET_TOP_WIDGET_WANLINK_STATUS_NEW_SUMM, payload: {}})
          dispatch({type:  GET_TOP_WIDGET_DATA_NEW_SUMM, payload: {}})
          setAlertsTopWidgetData([]);
          return;
        }

      }
      setVessels(vessels)
    }

    return () => {
      _requestAbortController.abort()
    }
  
  }, [authReducer.filteredVessels, search, vesselList]);

  useEffect(() => {
    let _requestAbortController = new AbortController();
    if(vessels.length > 0) {
      tabValue != 2 && getTopWidgetWanlinkStatusNewSumm(vessels, search, selectedOu?.name, _requestAbortController.signal, filter, newSummaryWanType?.value, newSummaryStartDate, newSummaryEndDate, tabValue);
      tabValue === 0 &&getTopWidgetDataNewSumm(vessels,search,newSummaryWanType?.value ,filter, newSummaryStartDate, newSummaryEndDate);
      tabValue === 1 && getNewSummaryUptimeAllLinks(vessels,search,newSummaryWanType?.value ,filter, newSummaryStartDate, newSummaryEndDate);
    }
  }, [vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, tabValue])

  useEffect(() => {
    if (!_.isEmpty(errorReducer.errorGetVesselSummaryDashboard)) {
      displayToastError(errorReducer.errorGetVesselSummaryDashboard.description)
      errorReducer.errorGetVesselSummaryDashboard = {};
    }
  }, [errorReducer.errorGetVesselSummaryDashboard]);

  const getUsage = (info) => {
    let usage = "-";
    if (null !== info && undefined !== info) {
      usage = readableBytesAsGB(info);
    }
    return usage;
  }

  const getDownlinkUsage = (wanType) =>{
    switch (wanType){
      case "VSAT":
        return getUsage(topWidgetData["d_vsat"])
      case "CELLULAR":
        return getUsage(topWidgetData["d_cellular"])
      case "STARLINK":
        return getUsage(topWidgetData["d_starlink"])
      case "LBAND":
        return getUsage(topWidgetData["d_lband"])
      case "BONDED":
        return getUsage(topWidgetData["d_vsat"])
      case "ETHERNET":
        return getUsage(topWidgetData["d_wired"])
      case "WIFI":
        return getUsage(topWidgetData["d_wifi"])
      default:
        return "total"
    }
  }

  const getUplinkUsage = (wanType) =>{
      switch (wanType){
        case "VSAT":
          return getUsage(topWidgetData["u_vsat"])
        case "CELLULAR":
          return getUsage(topWidgetData["u_cellular"])
        case "STARLINK":
          return getUsage(topWidgetData["u_starlink"])
        case "LBAND":
          return getUsage(topWidgetData["u_lband"])
        case "BONDED":
          return getUsage(topWidgetData["u_vsat"])
        case "ETHERNET":
          return getUsage(topWidgetData["u_wired"])
        case "WIFI":
          return getUsage(topWidgetData["u_wifi"])
        default:
          return "0"
      }
    }

    const getTotalUsage = (wanType) =>{
      switch (wanType){
        case "lte":
          return getUsage(topWidgetData['cellular'])
        default:
          return getUsage(topWidgetData[wanType])
      }
    }
    const getAvailableLink = (wanType) => ({"VSAT": cardetails?.A_VSAT_links, "CELLULAR": cardetails?.A_LTE_links, "STARLINK": cardetails?.A_STAR_links, "ETHERNET": cardetails?.A_WIRED_links, "WIFI": cardetails?.A_WIFI_links , "LBAND": cardetails?.A_FBB_links}[wanType] || 0) || "0";
    const getTotalLink = (wanType) => ({"VSAT": cardetails?.T_VSAT_links, "CELLULAR": cardetails?.T_LTE_links, "STARLINK": cardetails?.T_STAR_links, "ETHERNET": cardetails?.T_WIRED_links, "WIFI": cardetails?.T_WIFI_links, "LBAND": cardetails?.T_FBB_links}[wanType] || 0) || "0";

    const getWantypeIndex = () => {
      if(newSummaryWanType?.value == 'lband') {
        return {index: 0, label: 'LBand', icon: cellular_icon, wan_array: 4 };
      }
      if(newSummaryWanType?.value == 'lte') {
        return {index: 1, label: 'Cellular', icon: cellular_icon, wan_array: 1};
      }
      if(newSummaryWanType?.value == 'starlink') {
        return {index: 2, label: 'Starlink', icon: Starlink_Icon, wan_array: 3};
      } else if(newSummaryWanType?.value == 'wired') {
        return {index: 4, label: 'ETHERNET', icon: Starlink_Icon, wan_array: 5};
      } else if (newSummaryWanType?.value == 'wifi') {
        return {index: 5, label: 'WIFI', icon: Starlink_Icon, wan_array: 6};
      }
      else {
        return {index: 3, label: 'VSAT', icon: vsat_icon, wan_array: 2};
      }

    }
  
    const GetCardDetails = ({newSummaryWanType}) => {
    return ( 
      <Grid container spacing={tabValue === 1 ? 1 : 2} justifyContent={"space-between"}>
        {tabValue === 1 && newSummaryWanType?.label === "All" && 
          <Grid item xs={2} className="new_summary_grid_item card-box-parent" >
              <Card className="card-widget_NewSummary new_summary_uptime flex_grow new-sum-cards-box-shadow" id={'addBoxShadow'}>
                <div>
                <div style={{display:"flex"}}>
                <Typography className="new_summary_uptime_value">{uptime}</Typography>
                <Typography className="new_summary_uptime_percent">{"%"}</Typography>
                </div>
                <Typography className="new_summary_uptime_typo">{"Availability"}</Typography>
                </div>
              </Card> 
          </Grid>
        } 
          {
            tabValue === 2 && newSummaryWanType?.label == 'All' ?
            <>
              {AlertsWidgetCards(`Availability`, availability_icon, WAN_ARRAY && WAN_ARRAY[4], tabValue, alertsTopWidgetData['total'], newSummaryWanType, alertsTopWidgetData)}
              {AlertsWidgetCards(`Starlink`, Starlink_Icon,  WAN_ARRAY && WAN_ARRAY[3], tabValue, alertsTopWidgetData['starlink'], newSummaryWanType)}
              {AlertsWidgetCards(`Cellular`, cellular_icon, WAN_ARRAY && WAN_ARRAY[1], tabValue, alertsTopWidgetData['lte'], newSummaryWanType)}
              {AlertsWidgetCards(`VSAT`, vsat_icon, WAN_ARRAY && WAN_ARRAY[2], tabValue, alertsTopWidgetData['vsat'], newSummaryWanType)}
              {AlertsWidgetCards(`LBand`, cellular_icon, WAN_ARRAY && WAN_ARRAY[4], tabValue, alertsTopWidgetData['Lband'], newSummaryWanType)}
              {AlertsWidgetCards(`WIFI`, security_icon, WAN_ARRAY && WAN_ARRAY[6], tabValue, alertsTopWidgetData['wifi'], newSummaryWanType)}
              {AlertsWidgetCards(`ETHERNET`, system_icon, WAN_ARRAY && WAN_ARRAY[5], tabValue, alertsTopWidgetData['wired'], newSummaryWanType)}
              {AlertsWidgetCards(`Quota`, SideNavQuotaControl, WAN_ARRAY && WAN_ARRAY[4], tabValue, alertsTopWidgetData['usage'], newSummaryWanType)}
              {AlertsWidgetCards(`Security`, security_icon, WAN_ARRAY && WAN_ARRAY[4], tabValue, alertsTopWidgetData['security'], newSummaryWanType)}
              {AlertsWidgetCards(`System`, system_icon, WAN_ARRAY && WAN_ARRAY[4], tabValue, alertsTopWidgetData['system'], newSummaryWanType)}
            </>
            : tabValue === 2 && newSummaryWanType?.label !== 'All' ?
            <>
              {AlertsWidgetCards(`${getWantypeIndex()?.label}`, getWantypeIndex()?.icon,  WAN_ARRAY && WAN_ARRAY[getWantypeIndex()?.wan_array], tabValue, alertsTopWidgetData[newSummaryWanType?.value], newSummaryWanType)}
            </>
            :
            newSummaryWanType?.label == 'All' ?
            <>
              {CardContents(`Starlink`,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["starlink"] && uptimeData[0]["starlink"]?.toFixed(2) + " %" : getUsage(topWidgetData?.starlink), cardetails?.A_STAR_links ? cardetails?.A_STAR_links : 0, cardetails?.T_STAR_links ? cardetails?.T_STAR_links : 0, selectedOu,Starlink_Icon,  WAN_ARRAY && WAN_ARRAY[3], tabValue)}
              {CardContents(`Cellular`,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["LTE"] && uptimeData[0]["LTE"]?.toFixed(2) + " %" : getUsage(topWidgetData?.cellular), cardetails?.A_LTE_links ? cardetails?.A_LTE_links : 0 , cardetails?.T_LTE_links ? cardetails?.T_LTE_links : 0,  selectedOu, cellular_icon, WAN_ARRAY && WAN_ARRAY[1], tabValue)}
              {CardContents(`VSAT ${tabValue === 1 ? "" : "Usage" } `,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["VSAT"] && uptimeData[0]["VSAT"]?.toFixed(2) + " %" : getUsage(topWidgetData?.vsat), cardetails?.A_VSAT_links ? cardetails?.A_VSAT_links : 0, cardetails?.T_VSAT_links ? cardetails?.T_VSAT_links : 0, selectedOu, vsat_icon, WAN_ARRAY && WAN_ARRAY[2], tabValue)}
              {CardContents(`LBand ${tabValue === 1 ? "" : "Usage" } `, tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["FBB"] && uptimeData[0]["FBB"]?.toFixed(2) + " %" : getUsage(topWidgetData?.lband), cardetails?.A_FBB_links ? cardetails?.A_FBB_links : 0, cardetails?.T_FBB_links ? cardetails?.T_FBB_links : 0, selectedOu, cellular_icon, WAN_ARRAY && WAN_ARRAY[4], tabValue)}
              {CardContents(`ETHERNET ${tabValue === 1 ? "" : "Usage" } `,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["ETHERNET"] && uptimeData[0]["ETHERNET"]?.toFixed(2) + " %" : getUsage(topWidgetData?.wired), cardetails?.A_WIRED_links ? cardetails?.A_WIRED_links : 0, cardetails?.T_WIRED_links ? cardetails?.T_WIRED_links : 0, selectedOu, vsat_icon, WAN_ARRAY && WAN_ARRAY[5], tabValue)}
              {CardContents(`WIFI ${tabValue === 1 ? "" : "Usage" } `, tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["WIFI"] && uptimeData[0]["WIFI"]?.toFixed(2) + " %" : getUsage(topWidgetData?.wifi), cardetails?.A_WIFI_links ? cardetails?.A_WIFI_links : 0, cardetails?.T_WIFI_links ? cardetails?.T_WIFI_links : 0, selectedOu, cellular_icon, WAN_ARRAY && WAN_ARRAY[6], tabValue)}
                    
            </>
            :
            tabValue === 1 ?
            <>
             {newSummaryWanType?.label == 'STARLINK' && CardContents(`Starlink ${tabValue === 1 ? "" : "Usage" } `,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["starlink"] && uptimeData[0]["starlink"]?.toFixed(2) + " %" : getUsage(topWidgetData?.starlink), cardetails?.A_STAR_links ? cardetails?.A_STAR_links : 0, cardetails?.T_STAR_links ? cardetails?.T_STAR_links : 0, selectedOu,Starlink_Icon,  WAN_ARRAY && WAN_ARRAY[3], tabValue)}
              { newSummaryWanType?.label == 'CELLULAR' &&CardContents(`Cellular ${tabValue === 1 ? "" : "Usage" } `,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["LTE"] &&  uptimeData[0]["LTE"]?.toFixed(2) + " %" : getUsage(topWidgetData?.cellular), cardetails?.A_LTE_links ? cardetails?.A_LTE_links : 0 , cardetails?.T_LTE_links ? cardetails?.T_LTE_links : 0,  selectedOu, cellular_icon, WAN_ARRAY && WAN_ARRAY[1], tabValue)}
              { newSummaryWanType?.label == 'VSAT' &&CardContents(`VSAT ${tabValue === 1 ? "" : "Usage" } `,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["VSAT"] && uptimeData[0]["VSAT"]?.toFixed(2) + " %" : getUsage(topWidgetData?.vsat), cardetails?.A_VSAT_links ? cardetails?.A_VSAT_links : 0, cardetails?.T_VSAT_links ? cardetails?.T_VSAT_links : 0, selectedOu, vsat_icon, WAN_ARRAY && WAN_ARRAY[2], tabValue)}
              { newSummaryWanType?.label == 'LBAND' &&CardContents(`LBand ${tabValue === 1 ? "" : "Usage" } `, tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["FBB"] && uptimeData[0]["FBB"]?.toFixed(2) + " %" : getUsage(topWidgetData?.lband), cardetails?.A_FBB_links ? cardetails?.A_FBB_links : 0, cardetails?.T_FBB_links ? cardetails?.T_FBB_links : 0, selectedOu, cellular_icon, WAN_ARRAY && WAN_ARRAY[4], tabValue)}
              {newSummaryWanType?.label == 'ETHERNET' && CardContents(`ETHERNET ${tabValue === 1 ? "" : "Usage" } `,tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["ETHERNET"] && uptimeData[0]["ETHERNET"]?.toFixed(2) + " %" : getUsage(topWidgetData?.wired), cardetails?.A_WIRED_links ? cardetails?.A_WIRED_links : 0, cardetails?.T_WIRED_links ? cardetails?.T_WIRED_links : 0, selectedOu, vsat_icon, WAN_ARRAY && WAN_ARRAY[5], tabValue)}
              {newSummaryWanType?.label == 'WIFI' && CardContents(`WIFI ${tabValue === 1 ? "" : "Usage" } `, tabValue===1 ? uptimeData && uptimeData[0] && uptimeData[0]["WIFI"] && uptimeData[0]["WIFI"]?.toFixed(2) + " %" : getUsage(topWidgetData?.wifi), cardetails?.A_WIFI_links ? cardetails?.A_WIFI_links : 0, cardetails?.T_WIFI_links ? cardetails?.T_WIFI_links : 0, selectedOu, cellular_icon, WAN_ARRAY && WAN_ARRAY[6], tabValue)}
                  
            </>
            :
            <>
              {CardContentsWanType(`${getDecodeURI(location?.search)?.wanType ? getDecodeURI(location?.search)?.wanType : ""} Usage`, getTotalUsage(newSummaryWanType?.value), tabValue)}
              {CardContentsWanType("Online Links", getAvailableLink(newSummaryWanType?.label), tabValue, getTotalLink(newSummaryWanType?.label))}
              {CardContentsWanType("Downlink Usage", getDownlinkUsage(newSummaryWanType?.label), tabValue)}
              {CardContentsWanType("Uplink Usage", getUplinkUsage(newSummaryWanType?.label), tabValue)}
            </>
          }
        </Grid>
      )
  }

  return (
    <Grid className="cards_NewSummary" container item>
      <GetCardDetails newSummaryWanType={newSummaryWanType} />
     </Grid>
  );
} 

const mapStateToProps = (state) => ({
  authReducer: state?.authReducer,
  setTopWidgetData:state?.authReducer?.setTopWidgetData,
  errorReducer: state?.errorReducer,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  newSummaryWanType:state?.authReducer?.newSummaryWanType,
  alertTopWidget: state?.authReducer?.alertTopWidget
});


export default withRouter(
  connect(mapStateToProps, {
    getTopWidgetDataNewSumm, 
    getTopWidgetWanlinkStatusNewSumm,
    getNewSummaryUptimeAllLinks,
    getAlertTopWidget
    
  })(SummaryCardDetails)
);


