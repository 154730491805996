import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, TableHead, Tooltip } from '@mui/material';
import close from "../../asset/image/close.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import './inventoryDeployed.css';
import { getDeviceLicenseHistory } from '../../actions/Users/authenticateInventory';
import _ from 'lodash';
import { GET_DEVICE_LICENSE_HISTORY } from '../../actions/types';
import { convertDateTimeIntoTimezone } from '../../utils/util';
import moment from "moment-timezone";
import { DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";

const DeviceLicenseHistoryPopup = (props) => {
    const { authReducer, open, setOpen, siteIdSelected, deviceIdSelected, getDeviceLicenseHistory } = props;

    const dispatch = useDispatch();

    const [deviceLicenseHistoryData, setDeviceLicenseHistoryData] = useState<any>([]);

    useEffect(() => {
        setDeviceLicenseHistoryData([])
        if (open) {
            getDeviceLicenseHistory(siteIdSelected, deviceIdSelected)
        }
    }, [open])

    useEffect(() => {
        if (!_.isEmpty(authReducer.deviceLicenseHistory)) {
            let data = authReducer.deviceLicenseHistory
            data?.map((row) => {
                let createdOn = row["created_on"]
                let date = createdOn.split("T")[0]
                let expiryDate = new Date(new Date(date).getTime() + (row["period"] * 1000));
                row["expiry_date"] = moment.utc(expiryDate, 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
            })
            setDeviceLicenseHistoryData(data)
            dispatch({ type: GET_DEVICE_LICENSE_HISTORY, payload: {} })
        }
    }, [authReducer.deviceLicenseHistory])

    return (
        <div className="Form menuScroll">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{ borderRadius: '8px', width: '1000px' }}
                className='config-deploy-inventory-popup menuScroll device-history-popup device-eos-history-popup'
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>{`License History (${deviceIdSelected})`}</span>
                        <img src={close} title="Close" onClick={() => setOpen(false)} />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className="deploy-inventory-content device-history-content">
                            <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                                <Table aria-label="simple sticky table" stickyHeader>
                                    <TableHead className="usageReports-tableHead">
                                        <TableRow className="UsageReports-tableRow login-tableRow">
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">LICENSE</TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">Expiry Date</TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">INITIATED BY</TableCell>
                                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell align-left">APPLIED ON</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {0 != deviceLicenseHistoryData.length && !authReducer.deviceLicenseHistoryLoading ? (
                                        <TableBody className="tableBody usage-tableBody">
                                            {
                                                _.sortBy(deviceLicenseHistoryData, 'created_on').reverse().map((row, i) =>
                                                    <TableRow key={i} className="loginHistory-tableRow">
                                                        <TableCell className="login-left-align short-text-username">{row["tier_alias"]}</TableCell>
                                                        <TableCell className="login-left-align short-text-username">
                                                            {convertDateTimeIntoTimezone(row['expiry_date'], authReducer.userTimezone, DEFAULT_DATE_FORMAT)}
                                                        </TableCell>
                                                        <TableCell className="login-left-align short-text-username">{row["user"]}</TableCell>
                                                        <TableCell className="login-left-align status">
                                                            {convertDateTimeIntoTimezone(row['created_on'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={5} className="noData">No data available</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});

export default withRouter(
    connect(mapStateToProps, { getDeviceLicenseHistory })(DeviceLicenseHistoryPopup)
);