import { withRouter, Switch, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchUserListAPI, parentDpAndSubDpList, updatePermission, updateSideMenu } from "../../actions/Users/authenticate";

import { StarlinkNavTabs, StarlinkPageProps } from './types';
import { Alert, Collapse, Grid, IconButton, Paper, Typography } from '@mui/material';

import "./index.css";
import Navigation, { TabPanel } from './Navigation';
import { Fragment, useEffect, useMemo, useState } from "react";
import { SIDE_MENU_ITEM_STARLINK } from "../../utils/constants";
import StarlinkReports from "../../components/StarlinkReports";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, populateForParent, setDataToLocalStorage, updateTimePeriod } from "../../utils/util";
import NotAuthorized from "../NotAuthorized";
import StarlinkAdmin from "../../components/StarlinkAdmin";
import StarlinkDashboard from "../../components/StarlinkDashboard";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getServiceAccounts } from "../../components/StarlinkAdmin/slice";
import UserGuideDoc from '../../asset/user_guide/Starlink_Cloud_User_Guide.pdf';
import { getLastThreeMonth } from "../../constants/Constants";


import _ from "lodash";
import { setDpIds, setServiceAccount } from "./slice";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import StarlinkToS from "../../components/StarlinkToS";
import { validateTosAcceptance } from "../../components/StarlinkToS/slice";
import StarlinkAnalytics from "../../components/StarlinkAnalytics";
import StarlinkQuota from "../../components/StarlinkQuota";
import TabsComponent from "../../components/NewTabs";
import StarlinkPooled from "../../components/StarlinkPooled";
import moment from "moment-timezone";
import MapLeaflet from "../../components/ServiceLines";

const STARLINK_SF = "starlink-user";
const starlinkService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_SF]["service"];
const starlinkFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_SF]["function"];
const periodOptions = ['1h','6h','12h','1d', '7d', '30d', 'MTD', 'customDates'];
const periodOptionsPlans = ['1h','6h','12h','1d', '7d', '30d', 'MTD', 'plans', 'customDates'];
const periodOptionsUsage = ['1d', '7d', '30d', 'MTD', ...getLastThreeMonth(), 'customDates'];
const STARLINK_ADMIN_SF = "starlink-admin";
const STARLINK_FULL_SF = "starlink-full";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
const starlinkFullService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["service"];
const starlinkFullFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["function"];

function Starlink(props: StarlinkPageProps) {
    const { authReducer, errorReducer, match, location, history, starlinkReports, serviceAccounts, starlinkToS, starlinkAnalytics, starlinkQuota, starlinkDashboard, dpIds, starlinkAdmin, validateTosAcceptance, parentDpAndSubDpList, updatePermission, updateSideMenu, setDpIds, setServiceAccount, fetchUserListAPI, getServiceAccounts, starlinkPooled } = props;
    const [loading, setLoading] = useState(false);
    const [isTosAccepted, setIsTosAccepted] = useState<boolean>(starlinkToS.tosAccepted);
    const [stepperValue, setStepperValue] = useState(0)
    const [activeTab, setActiveTab] = useState<StarlinkNavTabs>(match?.params?.tab ? match.params.tab : "starlinkMap");
    const [authorizedToViewTos, setAuthorizedToViewTos] =  useState<boolean>(false);
    const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<boolean>(false);
    const not_authorized_page_title = "Starlink Cloud Management";
    const not_authorized_page_message = "You are not authorised to view Starlink Cloud Management.";
    const [errorInTosValidation, setErrorInTosValidation] = useState(false)
    const dispatch = useDispatch();

    useEffect(()=>{
        setIsTosAccepted(starlinkToS.tosAccepted) 
    },[starlinkToS])

    useEffect(() => {
       
        if ((authReducer && !authReducer.sideMenu) || (SIDE_MENU_ITEM_STARLINK !== authReducer.sideMenu.menuItem)) {
            updateSideMenu({ menuItem: SIDE_MENU_ITEM_STARLINK });
        }
        return () => {
            // clear component data, no slice available for this component as of now
            // child component level clearing is handled already for existing components
        }
    }, [])

    useEffect(() => {
        if (match?.params?.tab) {
            setActiveTab(match.params.tab);
        }
    }, [match?.params?.tab]);

    useEffect(() => {
        let authorized;
        
        if (activeTab === "admin") {
            let service = starlinkAdminService;
            let func = starlinkAdminFunction;
            let serviceFull = starlinkFullService;
            let funcFull = starlinkFullFunction;
            let authorizedAdmin = checkLoggedInUserAuthorizedToViewPage(service, func);
            let authorizedFull = checkLoggedInUserAuthorizedToViewPage(serviceFull, funcFull);
            authorized = authorizedAdmin || authorizedFull
            setIsAuthorizedToViewPage(authorizedAdmin || authorizedFull);
        } else {
            let service = starlinkService;
            let func = starlinkFunction;
            let serviceFull = starlinkFullService;
            let funcFull = starlinkFullFunction;
            let authorizedService = checkLoggedInUserAuthorizedToViewPage(service, func);
            let authorizedFull = checkLoggedInUserAuthorizedToViewPage(serviceFull, funcFull);
            authorized = authorizedService || authorizedFull
            setIsAuthorizedToViewPage(authorizedService || authorizedFull);    
        }
        if (authorized) {
            if (authReducer && authReducer.selectedOu) {
                const info = {
                    permission: {
                        service: starlinkAdminService,
                        serviceFunction: starlinkAdminFunction,
                    },
                    isMultiVessel: false,
                    isSingleVessel: false,
                    showOu: true,
                    ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
                };
                updatePermission(info);
            }
            if (authReducer?.selectedOu?.name) {
                fetchUserListAPI(authReducer.selectedOu.name, 1, 1000000, '');
            }
        }
    }, [activeTab, authReducer.selectedOu])

    useEffect(()=>{
        if (authReducer?.selectedOu?.id ) {
            validateTosAcceptance({dpId:authReducer?.selectedOu?.id})
        }

        const service = starlinkAdminService ;
        const func = starlinkAdminFunction ;
        let authorizedToViewTos = checkLoggedInUserAuthorizedToViewPage(service, func);

        if(authorizedToViewTos){
            setAuthorizedToViewTos(authorizedToViewTos)
        }

    },[authReducer.selectedOu])

    const getChildren = (dpIds, dps) => {
        for (let i = 0; i < dps?.length; i++) {
            let id = dps[i]?.id;
            dpIds.push(id);
            if (dps[i].hasOwnProperty('_childern') && dps[i]['_childern']?.length > 0) {
                getChildren(dpIds, dps[i]['_childern'])
            }
        }
    }

    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            for (let i = 0; i < ous?.length; i++) {
                const item = ous[i];
                if (arr.hasOwnProperty(item.id)) {
                    const temp: any = arr[item.id];
                    arr[item.id] = { ...temp, ...item._childern };
                } else {
                    arr[item.id] = item._childern;
                }
                getDPTree(item._childern, arr);
            }
        }
    }

    const getDpList = (dpList) => {
        const tempArr: any = {};
        for (let i = 0; i < dpList.length; i++) {
            const _organisations = [];
            populateForParent(dpList[i], null, _organisations);
            getDPTree(_organisations, tempArr);
        }
        parentDpAndSubDpList(tempArr);
    }

    useEffect(() => { 
        let params: any = getDecodeURI(location?.search);
        delete params.starlinkTab;
        const { startDate, endDate } = updateTimePeriod(dispatch, location);
        params.startDate = startDate;
        params.endDate = endDate;
        history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
    }, [])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])

    useEffect(() => {
        if (!_.isEmpty(authReducer.selectedOu)){
            getServiceAccounts({
                dpIds,
                searchText: "",
               sortBy:"client_id",
               sortOrder:"desc",
               limit:10,
               page: 1
            });
        }
        if (!_.isEmpty(authReducer.selectedOu) && authReducer.selectedOu.hasOwnProperty("id") && authReducer.selectedOu.id && authReducer.selectedOu.hasOwnProperty("name") && authReducer.selectedOu.name && !_.isEmpty(authReducer.parentDpAndSubDpList)) {
            const parentDpId = authReducer?.selectedOu?.id;
            const dpIds = authReducer?.parentDpAndSubDpList;
            if (dpIds.hasOwnProperty(parentDpId)) {
                const _dpIds: any = [];
                _dpIds.push(parentDpId);
                getChildren(_dpIds, dpIds[parentDpId]);
                return setDpIds(_dpIds.filter(id => id !== ""));
            }
        }
        return setDpIds([]);
    }, [authReducer.selectedOu, authReducer.parentDpAndSubDpList])

    const handleChangeActiveTab = (event: React.SyntheticEvent, newValue: StarlinkNavTabs) => {
        const currentParams = getDecodeURI(location?.search);
        const params:any = {};
        const filterParams = function (requiredKeys: string[]) {
            for (let key of requiredKeys) {
                if (currentParams.hasOwnProperty(key)) {
                    params[key] = currentParams[key];
                }
            }
        }
        switch (newValue) {
            case "dashboard":
                filterParams(['startDate', 'endDate', 'interval', 'size', 'search', 'ouName', 'ouId', 'sideNav', 'serviceLineAccess', 'selectedAccount']);
                break;
            case "starlinkMap":
                filterParams(['startDate', 'endDate', 'interval', 'size', 'search', 'ouName', 'ouId', 'sideNav', 'serviceLineAccess', 'selectedAccount']);
                break;
            case "usage":
                filterParams(['startDate', 'endDate', 'interval', 'size', 'search', 'ouName', 'ouId', 'sideNav', 'serviceLineAccess', 'selectedAccount']);
                break;
            case "pooled":
                filterParams(['startDate', 'endDate', 'interval', 'size', 'search', 'ouName', 'ouId', 'sideNav', 'selectedAccount']);
                break;
            case "quota":
                filterParams(['startDate', 'endDate', 'interval', 'size', 'search', 'ouName', 'ouId', 'sideNav', 'serviceLineAccess']);
                break;
            case "analytics":
                filterParams(['startDate', 'endDate', 'interval', 'size', 'search', 'ouName', 'ouId', 'sideNav', 'serviceLineAccess']);
                break;
            case "admin":
                filterParams(['startDate', 'endDate', 'interval', 'ouName', 'ouId', 'sideNav', 'serviceLineAccess', 'selectedAccount']);
                break;
            default:
                break;
        }
        const { startDate, endDate } = updateTimePeriod(dispatch, location);
        if(getLastThreeMonth().includes(params.interval)){
            params.interval = "MTD"
             params.startDate = moment().startOf('month').valueOf();
              params.endDate = moment().valueOf();
        }else{
            params.startDate = startDate;
            params.endDate = endDate;
        }
       
        history.replace({ pathname: `/starlink/${newValue}`, search: getEncodedURI(params) })
        setActiveTab(newValue);
    };

    const helpAction = () => {
        window.open(UserGuideDoc)
    }

    useEffect(() => {
        setLoading(
            starlinkReports.gettingAssets ||
            starlinkReports.gettingAssetsCSV ||
            starlinkReports.gettingAssetUsage ||
            starlinkReports.gettingAssetCurrentBillingCcleUsage ||
            starlinkReports.settingQuotaAlert ||
            starlinkDashboard.gettingServiceLines ||
            starlinkAdmin.gettingServiceAccounts ||
            starlinkAdmin.creatingServiceAccount ||
            starlinkAdmin.updatingServiceAccount ||
            starlinkAdmin.deletingServiceAccount ||
            starlinkAdmin.gettingServiceLines ||
            starlinkAdmin.updatingServiceLine ||
            starlinkAdmin.gettingUserTerminals ||
            starlinkAdmin.subcribingServiceLine ||
            starlinkAnalytics.gettingMetricSeries > 0 ||
            starlinkAnalytics.gettingUserTerminals ||
            starlinkQuota.gettingServiceLineQuotas ||
            starlinkQuota.creating ||
            starlinkQuota.deleting ||
            starlinkQuota.updating ||
            authReducer.loading ||
            starlinkPooled.gettingPooledUsagePie ||
            starlinkPooled.gettingPooledBarChart ||
            starlinkPooled.gettingPooledTopCards ||
            starlinkPooled.gettingPooledlineChart ||
            starlinkPooled.gettingPooledTable ||
            starlinkPooled.gettingPooledUsagePieSplit    
        )
    }, [starlinkReports.gettingAssets, starlinkReports.gettingAssetsCSV, starlinkReports.gettingAssetUsage, starlinkReports.gettingAssetCurrentBillingCcleUsage, starlinkDashboard.gettingServiceLines, starlinkAdmin.gettingServiceAccounts, authReducer.loading, starlinkReports.settingQuotaAlert, starlinkAdmin.creatingServiceAccount, starlinkAdmin.updatingServiceAccount, starlinkAdmin.deletingServiceAccount, starlinkAdmin.gettingServiceLines, starlinkAdmin.updatingServiceLine, starlinkAdmin.gettingUserTerminals, starlinkAdmin.subcribingServiceLine, starlinkAnalytics.gettingMetricSeries, starlinkAnalytics.gettingUserTerminals, starlinkQuota.gettingServiceLineQuotas, starlinkQuota.creating, starlinkQuota.deleting, starlinkQuota.updating,
        starlinkPooled.gettingPooledUsagePie, starlinkPooled.gettingPooledBarChart, starlinkPooled.gettingPooledTopCards, starlinkPooled.gettingPooledlineChart, starlinkPooled.gettingPooledTable, starlinkPooled.gettingPooledUsagePieSplit
    ])
    
    useEffect(()=>{
        setErrorInTosValidation(starlinkToS.errorInTosValidation)
    },[starlinkToS.errorInTosValidation])

    const goToStarlinkDashborad = (value) =>{
        setStepperValue(value)
    }

    const tabs = [
        { tabName: 'Dashboard', tabValue: 'starlinkMap', pathName: '/starlink/starlinkMap' },
        // { tabName: 'Dashboard', tabValue: 'dashboard', pathName: '/starlink/dashboard' },
        { tabName: 'Usage', tabValue: 'usage', pathName: '/starlink/usage' },
        { tabName: 'Plans', tabValue: 'pooled', pathName: '/starlink/pooled', hideTab : checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? "No" : "Yes" },
        { tabName: 'Quota', tabValue: 'quota', pathName: '/starlink/quota', hideTab : checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? "No" : "Yes" },
        { tabName: 'Insights', tabValue: 'analytics', pathName: '/starlink/analytics' },
        { tabName: 'Admin', tabValue: 'admin', pathName: '/starlink/admin/:tab', disable: (checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) || checkLoggedInUserAuthorizedToViewPage(starlinkFullService, starlinkFullFunction)) ? "No" : "Yes" }
    ]
    
    useEffect(() => {
        setServiceAccount(serviceAccounts && serviceAccounts.length > 0 && serviceAccounts[0] && serviceAccounts[0]?.serviceAccounts)
    }, [serviceAccounts])

    return (
        <Grid className="starlink--container">
            <CommonFilters 
            disableOu={stepperValue > 1 && (!isTosAccepted || stepperValue === 2 )} 
            isMultiVessel={true}
            loading={loading || authReducer?.userPreferencesLoading || starlinkAdmin?.addingTopup} 
            showSummaryPeriod={ (activeTab === "dashboard" || activeTab === "usage"|| activeTab === "analytics" || activeTab == 'pooled')} 
            periodOptions={ activeTab === "usage" ? periodOptionsUsage :  activeTab === "pooled" ? periodOptionsPlans : periodOptions} 
            showLastThreeMonths = { activeTab === "usage" }
            showPlans = { activeTab === "pooled" }
            showWantType={false} 
            helpIcon={false} helpAction={helpAction} 
            userGuide={[true, 'starlink-cloud-user-guide']}
            showSLDropDown={activeTab != 'pooled'}
            showAccounts={ (activeTab === "dashboard" || activeTab === "usage" || location.pathname?.includes("/starlink/admin/service_line") || location.pathname?.includes("/starlink/admin/user_terminal"))}
            >
            </CommonFilters>
            {
                 !isTosAccepted || stepperValue === 2 ?
                    authorizedToViewTos ?
                        errorInTosValidation ?
                            <NotAuthorized pageTitle={"Unable to authenticate Starlink Terms of Service (ToS)."} PageMessage={"Unable to authenticate Starlink Terms of Service (ToS)."} />
                            :
                            <StarlinkToS loading={starlinkToS.validateTosLoading} goToStarlinkDashborad={goToStarlinkDashborad} />
                        : starlinkToS.validateTosLoading ? <NotAuthorized pageTitle={"Validating ToS "} PageMessage={"Validating ToS. Please Wait.."} /> : <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
                    :
                    <>
                        {/* <TrialBanner /> */}
                        {/* <Navigation value={activeTab} disableAdmin={!(checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) || checkLoggedInUserAuthorizedToViewPage(starlinkFullService, starlinkFullFunction))} onChange={handleChangeActiveTab} /> */}
                        <TabsComponent tabs={tabs} handleChangeTab={handleChangeActiveTab} activeTab={activeTab} />
                        {
                            isAuthorizedToViewPage ? <Fragment>
                                <Grid className={activeTab == 'admin' ? "starlink--tab_panel--container starlink-page-container" : "starlink--tab_panel--container"}>
                                    <Switch>
                                        <Route path="/starlink/dashboard" component={StarlinkDashboard} />
                                        <Route path="/starlink/usage" component={StarlinkReports} />
                                        <Route path="/starlink/pooled" component={StarlinkPooled} />
                                        <Route path="/starlink/quota" component={StarlinkQuota} />
                                        <Route path="/starlink/analytics" component={StarlinkAnalytics} />
                                        <Route path="/starlink/starlinkMap" component={MapLeaflet} />
                                        <Route path="/starlink/admin/:tab?" component={StarlinkAdmin} />
                                    </Switch>
                                </Grid>
                            </Fragment>
                                : <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
                        }
                    </>
            }
        </Grid>
    );
}

function TrialBanner() {
    const [showNote, setShowNote] = useState<boolean>(false);
    return (
        <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
            <Alert
                severity="info"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setShowNote(!showNote);
                        }}
                    >
                        {showNote ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
                    </IconButton>
                }
            >
                <Typography component="div" className="starlink--base--font_09x starlink--base--text_start">
                    <Typography component="span" className="starlink--base--font_09x starlink--base--font_bold">"Trial Access - Beta Release"</Typography> Starlink Cloud is available on a trial basis for a limited time. Full features, access level, and pricing are being evaluated for future release.
                    {
                        <Collapse in={showNote}>
                            <Typography component="div" className="starlink--base--font_08x starlink--base--text_start">
                                Note: The Service Portal Starlink Cloud service provides Starlink service and terminal information by accessing the Starlink cloud directly, and thus does not require local/direct access to the Starlink terminal.  Further, if one has a K4Edge Server on site with the Starlink Terminal, then the Service Portal will provide both Terminal and Cloud data as a part of the KAAS Subscription.
                            </Typography>
                        </Collapse>
                    }
                </Typography>
            </Alert>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkReports: state.starlinkReports,
    starlinkDashboard: state.starlinkDashboard,
    starlinkAdmin: state.starlinkAdmin,
    starlinkToS: state.starlinkToS,
    starlinkAnalytics: state.starlinkAnalytics,
    starlinkQuota: state.starlinkQuota,
    dpIds: state.starlinkCloud.dpIds,
    serviceAccounts: state.starlinkAdmin.serviceAccounts,
    starlinkPooled: state.starlinkPooled,
});

export default withRouter(
    connect(mapStateToProps, {
        parentDpAndSubDpList,
        updatePermission,
        updateSideMenu,
        setDpIds,
        fetchUserListAPI,
        validateTosAcceptance,
        getServiceAccounts,
        setServiceAccount
    })(Starlink)
);