import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { useMemo, useState } from "react";
import { Box, Grid, LinearProgress, Paper } from "@mui/material";
import moment from "moment-timezone";
import ChartUnSelected from "../../../asset/image/ChartUnSelected.svg";
import { getTimezoneCity } from "../../../utils/util";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";

NoDataToDisplay(Highcharts);

const useStyles:any = makeStyles({
    colorPrimary: {
        backgroundColor: '#D6DAFA',
    },
    barColorPrimary: {
        backgroundColor: '#2F4B82',
    }
});

const AssetUsageBarChart = (props) => {
    const { startDate, endDate, assetUsage, assetUsageSplit, userTimezone, newSummaryServiceLine } = props;
    const timeZone = getTimezoneCity(userTimezone);
    const [usageForAllServiceLines, setUsageForAllServiceLines] = useState(0);

    const formattedData = useMemo(() => {
        const data:any = {
            standard_gb: [],
            priority_gb: [],
            optIn_priority_gb: [],
            non_billable_gb:[]
        };
    
        const cards: any = [
            { key: 'total_priority_gb', label: 'PRIORITY' },
            { key: 'total_standard_gb', label: 'STANDARD' },
            { key: 'total_non_billable_gb', label: 'NON-BILLABLE' },
            { key: 'total_optIn_priority_gb', label: 'OPT-IN PRIORITY' }
        ];
    
        // Process assetUsageSplit for time series data
        assetUsageSplit && Object.keys(assetUsageSplit)?.forEach((key) => {
            const series = assetUsageSplit?.[key];
            series?.forEach(({ x, y }) => {
                const timestamp = x;
                const usage_value = y;
                if (key === "standard_gb") data.standard_gb.push([timestamp, usage_value]);
                if (key === "optIn_priority_gb") data.optIn_priority_gb.push([timestamp, usage_value]);
                if (key === "non_billable_gb") data.non_billable_gb.push([timestamp, usage_value]);
                if (key === "priority_gb") data.priority_gb.push([timestamp, usage_value]);
            });
        });
        
        // Create cardsData using the first `y` value in the array for each key (total value)
        const cardsData = cards.map(({ key, label }) => {
            const series = assetUsageSplit?.[key];
            const total = series && series.length > 0 ? series[0].y.toFixed(2) : '0.00';
            return {
                label: label,
                value: total
            };
        });
    
        return { data, cardsData };
    }, [assetUsage, assetUsageSplit]);

    const series = [
        // {
        //     name: "Priority GB",
        //     data: formattedData?.data?.priority_gb,
        //     stack: "gb",
        //     color: "#3a88d3",
        // },
      
        {
            name: "Standard GB",
            data: formattedData?.data?.standard_gb,
            stack: "gb",
            color: "#1d3fa6",
        },
        {
            name: "Non Billable GB",
            data: formattedData?.data?.non_billable_gb,
            stack: "gb",
            color: "#8ac9e3",
        },
        {
            name: "Opt-In Priority GB",
            data: formattedData?.data?.optIn_priority_gb,
            stack: "gb",
            color: "#4464f3",
        }
    ];

   

    const options = {
        time: {
            timezone: timeZone,
        },
        chart: {
            type: "column",
            height: "400px",
            zoomType: "x",
            plotBorderWidth: 1,
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        title: {
            text: "",
            align: "left",
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: "500",
            },
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "15px",
                color: "#303030",
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        tooltip: {
            shared: true,  
            pointFormat:
                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} GB</b><br/>',
            valueDecimals: 0,
            borderWidth: 1,
        },
        xAxis: {
            gridLineWidth: 0.5,
            type: "datetime",
            min: moment(startDate).utc().toDate().valueOf(),
            max: moment(endDate).utc().toDate().valueOf(),
        },
        yAxis: {
            // tickInterval: 50,
            gridLineWidth: 1,
            labels: {
                format: "{value} GB",
            },
            title: {
                text: null,
            },
        },
        series: series,
    };

    function addCommasToNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Calculate total usage for Priority, Standard, and Non-Billable
    const totalUsage = Number(formattedData?.cardsData[0]?.value) +  Number(formattedData?.cardsData[1]?.value) +  Number(formattedData?.cardsData[2]?.value);

    // Calculate progress for each item
    const calculateProgress = (value, total) => (value / total) * 100;

    // Function to format values based on size (GB or TB)
    const formatUsage = (valueInGB) => {
        if (Number(valueInGB) >= 1000) {
            const tbValue = Number(valueInGB) / 1000;
            return `${tbValue.toFixed(2)} TB`;
        } else {
            return `${Number(valueInGB).toFixed(2)} GB`;
        }
    };

    return (
        <>
            <Grid className="cards" container columns={10} spacing={2}>
                {CardContents("TOTAL USAGE", `${formatUsage(totalUsage)}`, 100, newSummaryServiceLine?.reduce((acc) => acc + 1, 0))}
                {formattedData?.cardsData?.map((item) => (
                    CardContents(item.label, `${formatUsage(item.value)}`, item.label === 'OPT-IN PRIORITY' ? 100 : calculateProgress(item.value, totalUsage), null)
                ))}
            </Grid>
         <Paper elevation={1} className="starlink--reports--usage_chart--container" classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_05x' }}>

                <HighchartsReact highcharts={Highcharts} options={options} />
            </Paper>
        </>
    );
};

const CardContents = (cardHeading, usage, progress, serviceLines) => {
    const classes = useStyles();
    return (
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <Card className="card-widget" id={'removeBoxShadow'} >
          <Box className="cardHeader cardHeader-serviceLines">
            <span className="cardTitle">{cardHeading}</span>
            {/* <span className="cardTitle">{serviceLines && `SERVICE LINES : ${serviceLines}`}</span> */}
          </Box>
          <Box className="cardContents">
              <Box className="cardActive">
                <div title={usage} className="content">{usage}</div>
              </Box>
          </Box>
          <Box sx={{ margin: '10px' }}>
            <LinearProgress sx={{ "--LinearProgress-radius": "10px"}} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}} variant="determinate" value={progress} />          
          </Box>
        </Card>
      </Grid>
    );
  }

const mapStateToProps = (state) => ({
    assetUsage: state.starlinkReports.assetUsage,
    assetUsageSplit: state.starlinkReports.assetUsageSplit,
    userTimezone: state.authReducer.userTimezone,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine
});

export default withRouter(connect(mapStateToProps, {})(AssetUsageBarChart));
