import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment, { Moment } from "moment-timezone";

import { getDecodeURI, getEncodedURI, getMinutes, getTimezoneCity } from '../../utils/util';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload, escapeJsonString } from "../DownloadCSV";
import { clearMetricSeries, getDeployedUserTerminalsByDP, getStarlinkMetricSeries,paramGetStarlinkUserTerminalAlerts, getStarlinkUserTerminalAlerts, paramClearMetricSeries, paramGetStarlinkMetricSeries, clearComponentData } from "./slice";
import { Autocomplete, Button, Dialog, Typography, TableBody, TableCell,  TableHead, TableRow, InputAdornment, Checkbox, AutocompleteChangeDetails, AutocompleteChangeReason, Box, Chip, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Switch, Table, TableContainer, TextField } from "@mui/material";
import { UserTerminal } from "../StarlinkAdmin/types";
import { AVAILABLE_STARLINK_METRICS, STARLINK_METRIC_DISPLAY_NAMES, YYYY_MM_DD_HH_MM_SS, StarlinkMetric, STARLINK_METRIC_UNITS, Interval } from "./constants";
import Chart from "../Charts";
import "../SiteCharts/SiteCharts.css";
import _, { cloneDeep } from "lodash";
import { CHART_COLORS } from "../../utils/Colors";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CustomLoader from "../Loader/CustomLoader";
import {alertMap} from "../../config"

interface StarlinkAnalyticsProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    userTerminals: any[];
    gettingUserTerminals: boolean;
    series: any;
    gettingMetricSeries: number;
    newSummaryStartDate: Moment;
    newSummaryEndDate: Moment;
    getDeployedUserTerminalsByDP: (parameters: any) => void;
    getStarlinkMetricSeries: (parameters: paramGetStarlinkMetricSeries) => void;
    getStarlinkUserTerminalAlerts: (parameters: paramGetStarlinkUserTerminalAlerts) => void;
    userTerminalAlerts:any;
    userTerminalAlertColumns:any,
    clearMetricSeries: (parameters: paramClearMetricSeries) => void;
    downloadAsCSV: (parameters: any) => void;
    clearComponentData: () => void;
    newSummaryServiceLine: any;
}

const getInterval = (minutes) => {
    if (minutes <= 60) {
        return "1 minute"
    } else if (minutes <= 1440 && minutes > 60) {
        return "3 minute"
    } else if (minutes > 1440 && minutes <= 10080) {
        return "20 minute"
    } else {
        return "12 hour";
    }
}

function StarlinkAnalytics(props: StarlinkAnalyticsProps) {
    const { authReducer, errorReducer, history, location, dpIds, userTerminals, gettingUserTerminals, userTerminalAlerts, userTerminalAlertColumns, series, gettingMetricSeries, newSummaryStartDate, newSummaryEndDate, newSummaryServiceLine, getDeployedUserTerminalsByDP, getStarlinkMetricSeries,getStarlinkUserTerminalAlerts, clearMetricSeries, downloadAsCSV, clearComponentData } = props;

    const queryParameters = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [chartParams, setChartParams] = useState<{
        startDate: moment.Moment;
        endDate: moment.Moment;
        selectedBin: string;
    }>({
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate,
        selectedBin: getInterval(Math.ceil((newSummaryStartDate.toDate().getTime() - newSummaryStartDate.toDate().getTime()) / 60e3))
    });
    const [selectedUserTerminals, setSelectedUserTerminals] = useState<UserTerminal[]>([]);
    const [selectedMetrics, setSelectedMetrics] = useState<StarlinkMetric[]>([]);

    const previousSelectedTerminals = useRef(selectedUserTerminals);
    const previousSelectedMetrics = useRef(selectedMetrics);


    let defaultChartOptions = {
        time: {
            timezone: getTimezoneCity(authReducer?.userTimezone)
        },
        chart: {
            type: 'spline',
            zoomType: 'x',
            height: "400px",
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            plotBorderColor: '#e8eaeb',
            plotBorderWidth: 1
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            y: 12,
            margin: 28,
            itemStyle: {
                color: '#3F3F3F'
            }
        },
        title: {
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            }
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 0.5,
        },
        yAxis: {
            title: {
                text: undefined
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        series: {
            type: 'spline',
            marker: {
                symbol: 'circle',
            },
        },
        showLegendTable: true
    }
    let globalColorCount = 0;

    const [chartOptions, setChartOptions] = useState<any>(defaultChartOptions);

    const updateQueryParams = () => {
        queryParameters.set('startDate', chartParams.startDate.valueOf().toString())
        queryParameters.set('endDate', chartParams.endDate.valueOf().toString())
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParameters.entries()))}` });
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleChangeSelectedUserTerminals = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        event.preventDefault();
        if (reason === "selectOption" || reason === "removeOption") {
            setSelectedUserTerminals(value as UserTerminal[])
        } else if (reason === "clear") {
            setSelectedUserTerminals([]);
        }
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setChartParams({
            ...chartParams,
            selectedBin: value
        });
    };

    const handleResetZoom = () => {
        const start = moment(Date.now()).subtract(1, 'days');
        const end = moment(Date.now());
        setChartParams({
            ...chartParams,
            startDate: start,
            endDate: end
        });
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = '1d';
        doNavigate(params);
    }

    const chartAreaSelectionHandler = () => {
        return (event: any) => {
            let start = new Date(Math.ceil(event.xAxis[0].min)).toISOString();
            let end = new Date(Math.floor(event.xAxis[0].max)).toISOString();
            setChartParams({
                ...chartParams,
                startDate: moment(start),
                endDate: moment(end)
            });
            let params = getDecodeURI(location?.search);
            params.startDate = new Date(Math.ceil(event.xAxis[0].min)).valueOf();
            params.endDate = new Date(Math.floor(event.xAxis[0].max)).valueOf();
            params.interval = 'customDates';
            doNavigate(params);
            return false;
        }
    }

    const getSeriesData = (series: any[], valueUnit: string, yAxisCount: any = 0, metric: any = null, useGlobalColorCount: boolean = false, tooltip: any = {}) => {
        return (() => {
            return Object.keys(series).map((key: string, i: any) => {
                return {
                    ...defaultChartOptions.series,
                    marker: {
                        symbol: 'circle',
                    },
                    name: `${metric} - ${userTerminals.find(ut => ut.userTerminalId === key)?.serviceLineName}`,
                    data: series[key],
                    color: useGlobalColorCount ? CHART_COLORS[globalColorCount++] : CHART_COLORS[i],
                    tooltip: _.isEmpty(tooltip) ? {
                        valueSuffix: valueUnit
                    } : tooltip,
                    yAxis: yAxisCount,
                    valueUnit: valueUnit
                };
            });
        })()
    }

    const getYAxis = (title, i) => {
        return {
            title: {
                text: title,
            },
            lineWidth: 1,
            opposite: i !== 0 ? true : false
        }
    }

    useEffect(() => {
        if (!_.isEmpty(userTerminals)) {
            let firstMetrics = AVAILABLE_STARLINK_METRICS[0]
            let firstUserTerminal = userTerminals[0]
            setSelectedUserTerminals([firstUserTerminal] as UserTerminal[])
            setSelectedMetrics([firstMetrics])
        }
    }, [userTerminals])

    useEffect(() => {
        updateQueryParams();
        AVAILABLE_STARLINK_METRICS.forEach(metric => {
            clearMetricSeries({
                metric: metric,
                userTerminalIds: undefined
            });
        });
        setSelectedUserTerminals([])
        setSelectedMetrics([])
        clearComponentData();
        getDeployedUserTerminalsByDP({
            dpIds,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl=>sl !== '') : [],
        });
    }, [ newSummaryServiceLine]);

    useEffect(() => {
        if (newSummaryStartDate.isSameOrBefore(newSummaryEndDate)) {
            setChartParams({
                ...chartParams,
                startDate: newSummaryStartDate,
                endDate: newSummaryEndDate,
                selectedBin: getInterval(Math.ceil((newSummaryEndDate.toDate().getTime() - newSummaryStartDate.toDate().getTime()) / 60e3))
            });
        }
    }, [newSummaryEndDate, newSummaryStartDate])

    useEffect(() => {
        if (!_.isEmpty(userTerminals)) {
            let newTerminals = _.difference(selectedUserTerminals, previousSelectedTerminals.current);
            newTerminals.forEach(ut => {
                selectedMetrics.forEach(metric => {
                    getStarlinkMetricSeries({
                        source: "",
                        start_time: chartParams.startDate.format(YYYY_MM_DD_HH_MM_SS),
                        end_time: chartParams.endDate.format(YYYY_MM_DD_HH_MM_SS),
                        interval: chartParams.selectedBin,
                        user_terminal_ids: ut.userTerminalId,
                        metric: metric
                    });
                });
            });
            let deletedTerminals = _.difference(previousSelectedTerminals.current, selectedUserTerminals);
            if (deletedTerminals.length > 0) {
                AVAILABLE_STARLINK_METRICS.forEach(metric => {
                    clearMetricSeries({
                        metric: metric,
                        userTerminalIds: deletedTerminals.map(ut => ut.userTerminalId)
                    });
                });
            }
        }
        
        getStarlinkUserTerminalAlerts({
            user_terminal_ids: selectedUserTerminals.map(item => item.userTerminalId).join(','),
            start_ts: chartParams.startDate.format(YYYY_MM_DD_HH_MM_SS),
            end_ts: chartParams.endDate.format(YYYY_MM_DD_HH_MM_SS)
        })
        previousSelectedTerminals.current = selectedUserTerminals;
    }, [selectedUserTerminals]);

    useEffect(() => {
        if (!_.isEmpty(selectedUserTerminals)) {
            let newMetrics = _.difference(selectedMetrics, previousSelectedMetrics.current);
            newMetrics.forEach(metric => {
                getStarlinkMetricSeries({
                    source: "",
                    start_time: chartParams.startDate.format(YYYY_MM_DD_HH_MM_SS),
                    end_time: chartParams.endDate.format(YYYY_MM_DD_HH_MM_SS),
                    interval: chartParams.selectedBin,
                    user_terminal_ids: selectedUserTerminals.map(ut => ut.userTerminalId).join(','),
                    metric: metric
                });
            })
            let deletedMetrics = _.difference(previousSelectedMetrics.current, selectedMetrics);
            if (deletedMetrics.length > 0) {
                deletedMetrics.forEach(metric => {
                    clearMetricSeries({
                        metric: metric,
                        userTerminalIds: undefined
                    });
                });
            }

            getStarlinkUserTerminalAlerts({
                user_terminal_ids: selectedUserTerminals.map(item => item.userTerminalId).join(','),
                start_ts: chartParams.startDate.format(YYYY_MM_DD_HH_MM_SS),
                end_ts: chartParams.endDate.format(YYYY_MM_DD_HH_MM_SS)
            })
            
           
        }
        previousSelectedMetrics.current = selectedMetrics;
    }, [selectedMetrics])

    useEffect(() => {
        selectedUserTerminals.forEach(ut => {
            selectedMetrics.forEach(metric => {
                getStarlinkMetricSeries({
                    source: "",
                    start_time: chartParams.startDate.format(YYYY_MM_DD_HH_MM_SS),
                    end_time: chartParams.endDate.format(YYYY_MM_DD_HH_MM_SS),
                    interval: chartParams.selectedBin,
                    user_terminal_ids: ut.userTerminalId,
                    metric: metric
                });

                getStarlinkUserTerminalAlerts({
                    user_terminal_ids: selectedUserTerminals.map(item => item.userTerminalId).join(','),
                    start_ts: chartParams.startDate.format(YYYY_MM_DD_HH_MM_SS),
                    end_ts: chartParams.endDate.format(YYYY_MM_DD_HH_MM_SS)
                })
            });
        });
    }, [chartParams]);

    useEffect(() => {
        if (!_.isEmpty(userTerminals) && queryParameters.get('kitSerialNumber') && !_.isEmpty( queryParameters.get('kitSerialNumber'))) {
            let firstMetrics = AVAILABLE_STARLINK_METRICS[0]
            const kitSerialNumberString = queryParameters?.get('kitSerialNumber') || '';
            const kitsFromDash = kitSerialNumberString.split(',').map(item => item.replace(/'/g, ''));
            const userTerminalsMap = new Map(userTerminals.map(terminal => [terminal.kitSerialNumber, terminal]));

            const selectedTerminals = kitsFromDash
                .map(kit => userTerminalsMap.get(kit))
                .filter(Boolean);
            setSelectedUserTerminals(selectedTerminals as UserTerminal[])
            setSelectedMetrics([firstMetrics])
        }
    }, [userTerminals,  queryParameters.get('kitSerialNumber')])

    useEffect(() => {
        console.log(series);
        let _chartOptions = {
            ...defaultChartOptions,
            time: {
                timezone: getTimezoneCity(authReducer?.userTimezone)
            },
            chart: {
                ...chartOptions.chart,
                events: {
                    selection: chartAreaSelectionHandler()
                },
                height: "450px"
            },
            title: {
                ...defaultChartOptions.title,
                text: "",
                floating: false
            },
            xAxis: {
                ...defaultChartOptions.xAxis,
                plotLines: (!_.isEmpty(userTerminalAlerts) ) ? userTerminalAlerts?.map(row => {
                    const [ts, device_id, alert] = row;
                    return {
                        value: moment(ts).valueOf(),
                        width: 0.5,
                        color:  'red'
                    }
                }) : []
            },
            series: (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedUserTerminals)) ? (() => {
                let yAxisCount = 0;
                globalColorCount = 0;
                let performanceMetricSeries: any[] = [];
                let eventSeries: any[] = [];
                if (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedUserTerminals)) {
                    performanceMetricSeries = selectedMetrics.map((metric, i) => {
                        let tmpYAxisCount;
                        switch (metric) {
                            case "downlink_throughput":
                                return !_.isEmpty(series?.downlink_throughput) ? getSeriesData(series?.downlink_throughput, STARLINK_METRIC_UNITS[metric], yAxisCount++, STARLINK_METRIC_DISPLAY_NAMES[metric], true) : []
                            case "uplink_throughput":
                                return !_.isEmpty(series?.uplink_throughput) ? getSeriesData(series?.uplink_throughput, STARLINK_METRIC_UNITS[metric], yAxisCount++, STARLINK_METRIC_DISPLAY_NAMES[metric], true) : []
                            case "ping_drop_rate":
                                return !_.isEmpty(series?.ping_drop_rate) ? getSeriesData(series?.ping_drop_rate, STARLINK_METRIC_UNITS[metric], yAxisCount++, STARLINK_METRIC_DISPLAY_NAMES[metric], true) : []
                            case "ping_latency_ms":
                                return !_.isEmpty(series?.ping_latency_ms) ? getSeriesData(series?.ping_latency_ms, STARLINK_METRIC_UNITS[metric], yAxisCount++, STARLINK_METRIC_DISPLAY_NAMES[metric], true) : []
                            case "obstruction_percent_time":
                                return !_.isEmpty(series?.obstruction_percent_time) ? getSeriesData(series?.obstruction_percent_time, STARLINK_METRIC_UNITS[metric], yAxisCount++, STARLINK_METRIC_DISPLAY_NAMES[metric], true) : []
                            case "signal_quality":
                                return !_.isEmpty(series?.signal_quality) ? getSeriesData(series?.signal_quality, STARLINK_METRIC_UNITS[metric], yAxisCount++, STARLINK_METRIC_DISPLAY_NAMES[metric], true) : []
                        }
                    }).flat();

                    if (!_.isEmpty(userTerminalAlerts) && userTerminalAlerts.length > 0) { 
                         let seriesData = userTerminalAlerts?.map(row => {
                              const [ts, device_id, alert] = row;
                              return {
                                  x: moment(ts, 'YYYY-MM-DD HH:mm:ss Z').valueOf(),
                                  y: 0,
                                  color: 'red ',
                                  description: `Device ID: <b>${device_id}</b></br> Alert: <b>${alertMap[parseInt(alert)] }</b>`
                              }
                          });

                      
                          eventSeries = [{
                              ...defaultChartOptions.series,
                              name: 'Event',
                              data: seriesData,
                              marker: {
                                  ...defaultChartOptions.series.marker,
                                  enabled: true,
                                  symbol: 'square'
                              },
                              tooltip: {
                                  pointFormat: '{point.description}'
                              },
                            //   yAxis: yAxisCount++
                          }]

                      }
                }
                return cloneDeep([...performanceMetricSeries, ...eventSeries ]);
            })() : [],
            yAxis: (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedUserTerminals)) ? (() => {
                let yAxisCount = 0;
                let performanceMetricAxes: any[] = [];
                if (!_.isEmpty(selectedMetrics) && !_.isEmpty(selectedUserTerminals)) {
                    performanceMetricAxes = selectedMetrics.map((metric, i) => {
                        switch (metric) {
                            case "downlink_throughput":
                                return series?.downlink_throughput ? getYAxis(`${STARLINK_METRIC_DISPLAY_NAMES["downlink_throughput"]} (Mbps)`, yAxisCount++) : []
                            case "uplink_throughput":
                                return series?.uplink_throughput ? getYAxis(`${STARLINK_METRIC_DISPLAY_NAMES["uplink_throughput"]} (Mbps)`, yAxisCount++) : []
                            case "ping_drop_rate":
                                return series?.ping_drop_rate ? getYAxis(`${STARLINK_METRIC_DISPLAY_NAMES["ping_drop_rate"]} (%)`, yAxisCount++) : []
                            case "ping_latency_ms":
                                return series?.ping_latency_ms ? getYAxis(`${STARLINK_METRIC_DISPLAY_NAMES["ping_latency_ms"]} (ms)`, yAxisCount++) : []
                            case "obstruction_percent_time":
                                return series?.obstruction_percent_time ? getYAxis(`${STARLINK_METRIC_DISPLAY_NAMES["obstruction_percent_time"]} (%)`, yAxisCount++) : []
                            case "signal_quality":
                                return series?.signal_quality ? getYAxis(`${STARLINK_METRIC_DISPLAY_NAMES["signal_quality"]}`, yAxisCount++) : []
                        }
                    }).flat();
                }
                return cloneDeep(performanceMetricAxes);
            })() : []
        };
        setChartOptions(_chartOptions);
    }, [series, userTerminalAlerts]);


    return (
        <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
            {/* Filters */}
            <Grid container>
                <Grid item xs={8} classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--gap--05x starlink--base--flex--row' }}>
                    <Autocomplete
                        id="user_terminals"
                        multiple
                        disableCloseOnSelect
                        fullWidth
                        loading={gettingUserTerminals}
                        options={userTerminals}
                        value={selectedUserTerminals}
                        onChange={handleChangeSelectedUserTerminals}
                        size="small"
                        getOptionLabel={(option: UserTerminal) => `${option.serviceLineName} (${option.kitSerialNumber})`}
                        renderInput={(params) => <TextField {...params} label="Starlinks" variant="outlined" />}
                        sx={{ maxWidth: 400, "& .MuiAutocomplete-inputRoot": { flexWrap: 'unset' } }}
                        renderTags={(value: UserTerminal[], getTagProps) => {
                            return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: 58, overflowY: 'auto' }}>
                                {value.map((option, index) => (
                                    <Chip {...getTagProps({ index })} size="small" label={`${option.serviceLineName} (${option.kitSerialNumber})`} />
                                ))}
                            </Box>
                        }}
                    />
                    <Autocomplete
                        id="metrics"
                        multiple
                        disableCloseOnSelect
                        fullWidth
                        loading={false}
                        options={AVAILABLE_STARLINK_METRICS}
                        value={selectedMetrics}
                        onChange={(event, value) => setSelectedMetrics(value)}
                        size="small"
                        getOptionLabel={(option) => `${STARLINK_METRIC_DISPLAY_NAMES[option]}`}
                        renderInput={(params) => <TextField {...params} label="Metrics" variant="outlined" />}
                        sx={{ maxWidth: 450, "& .MuiAutocomplete-inputRoot": { flexWrap: 'unset' } }}
                        renderTags={(value: string[], getTagProps) => {
                            return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: 58, overflowY: 'auto' }}>
                                {value.map((option, index) => (
                                    <Chip {...getTagProps({ index })} size="small" label={STARLINK_METRIC_DISPLAY_NAMES[option]} />
                                ))}
                            </Box>
                        }}
                    />
                </Grid>
                <Grid item xs={4} classes={{ root: 'starlink--base--flex--row starlink--base--flex--justify--end starlink--base--flex--align--center starlink--base--flex--gap--05x' }}>
                    <FormControl variant="standard" className='selectEntry-pie sdwan-select-bin'>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={chartParams.selectedBin}
                            onChange={handleBinChange}
                        >
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1 && <MenuItem value={'1 minute'}>1m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 3 && <MenuItem value={'3 minute'}>3m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5 minute'}>5m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minute'}>10m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15 minute'}>15m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 20 && <MenuItem value={'20 minute'}>20m</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                            {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        value="Reset Zoom"
                        control={<IconButton onClick={handleResetZoom}><RestartAltIcon /></IconButton>}
                        label="Reset Zoom"
                        labelPlacement="start"
                    />
                </Grid>
            </Grid>
            {/* Charts */}
            <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                <Chart chartOptions={chartOptions} immutable={true} updateArgs={[true, false, true]} parameters={{}} page={0} setPage={0} enableEventsTable={false} uptimeChart={''} />
            </Grid>
            <Grid>
            {0 != userTerminalAlerts.length && 0 != selectedMetrics.length ? (
            <TableContainer className="alertsTable-FleetDashboard">
            <Table aria-label="simple table">
                <TableHead className="tableHead alertsTable-tableHead">
                <TableRow className="alertReports-tableRow">
                <TableCell className="alertsTable-fleetDataTable font-wt-900">USER TERMINAL</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">ALERT</TableCell>
                    <TableCell className="alertsTable-fleetDataTable font-wt-900">TIMESTAMP</TableCell>
                </TableRow>
                </TableHead>
                
                <TableBody className="tableBody alertsTable-tableBody">
                    {
                    userTerminalAlerts.length > 0 && userTerminalAlerts.map((row) =>{
                        const [ts, device_id, alert] = row;
                        return (
                        <TableRow className="accounts-tabelRow">
                        <TableCell className="accounts-tableCell noWrapAccounts"> {device_id} </TableCell>
                        <TableCell className="accounts-tableCell noWrapAccounts"> {alertMap[alert]} </TableCell>
                        <TableCell className="accounts-tableCell noWrapAccounts"> {moment(ts).format(YYYY_MM_DD_HH_MM_SS)} </TableCell>
                        </TableRow>
                        ) }  )
                    }
                </TableBody>
            </Table>
          </TableContainer> )
          :  null }
            </Grid>
            {/* Full Screen Blocking Loader to prevent frequent clicks and API calls */}
            {
                gettingMetricSeries > 0 ?
                    <CustomLoader
                    showLoader={true}
                    loadingText={ "Fetching data please wait..." }
                    />
               : null
            }
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    userTerminals: state.starlinkAnalytics.userTerminals,
    gettingUserTerminals: state.starlinkAnalytics.gettingUserTerminals,
    series: state.starlinkAnalytics.series,
    userTerminalAlerts: state.starlinkAnalytics.userTerminalAlerts,
    userTerminalAlertColumns: state.starlinkAnalytics.userTerminalAlertColumns,
    gettingMetricSeries: state.starlinkAnalytics.gettingMetricSeries,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine
});

export default withRouter(
    connect(mapStateToProps, {
        getDeployedUserTerminalsByDP,
        getStarlinkMetricSeries,
        getStarlinkUserTerminalAlerts,
        clearMetricSeries,
        downloadAsCSV,
        clearComponentData
    })(StarlinkAnalytics)
);