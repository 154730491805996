import { useEffect, useRef, useState } from "react";
import L, { Map as LeafletMap } from "leaflet";
import { MapContainer, Marker, TileLayer, Tooltip, useMapEvents, ZoomControl } from "react-leaflet";
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button, ButtonGroup } from "@mui/material";
import "leaflet/dist/leaflet.css";
import "./StarlinkMaps.css";
import _ from "lodash";
import { getDecodeURI, getEncodedURI } from '../../utils/util';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { getStarlinkMapTelemetry } from "../StarlinkDashboard/slice"
// import LayersIcon from '@mui/icons-material/Troubleshoot';
import PerformanceIcon from "../../asset/image/PerformanceIcon.svg"
import Control from "react-leaflet-custom-control";
import { VITE_ACCESS_TOKEN, VITE_USERNAME, VITE_STYLE_ID } from "../../config";

const StarlinkMap = (props) => {
    const { location, starlinkLocations, serviceLines, selectedServiceLine, setSelectedServiceLine, getStarlinkMapTelemetry, starlinkMapTelemetry, mapClick, setMapClick, tableSearch } = props;
    const history = useHistory();
    const [map, setMap] = useState<LeafletMap | null>(null);
    const [zoomLevel, setZoomLevel] = useState<number>(2);
    const [selectedTerminal, setSelectedTerminal] = useState<any>(null);
    const [open, setOpen] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState<{
        service_line_name: string;
        service_line_number: string;
        lat: number;
        long: number;
    } | null>(null);

    const [selectedServiceLineLocal, setSelectedServiceLineLocal] = useState<any>(null)
    const [starlinkMapTelemetryLocal, setStarlinkMapTelemetryLocal] = useState<any>(null)
    const prevServiceLineRef = useRef(selectedServiceLineLocal);

    useEffect(() => {
        setSelectedServiceLineLocal(selectedServiceLine);
    }, [selectedServiceLine])

    useEffect(() => {
        setStarlinkMapTelemetryLocal(starlinkMapTelemetry);
    }, [starlinkMapTelemetry])

    const handleChangeTerminal = (terminal) => {
        setSelectedTerminal(terminal);
        getStarlinkMapTelemetry({
            userTerminalId: terminal?.userTerminalId
        })
    }

    const handleInsightsClick = (row) => {
        let kits;
        if (row?.userTerminals !== '' && JSON.parse(row?.userTerminals)) {
            kits = JSON.parse(row?.userTerminals)?.map((userTerminal: any) => {
                return userTerminal?.kitSerialNumber;
            });
        }

        let params: any = getDecodeURI(location?.search);
        params.kitSerialNumber = kits.map((kit) => `'${kit}'`).join(',');
        delete params.page;
        const newTabURL = `/starlink/analytics?${getEncodedURI(params)}&serviceLineAccess=All`;
        window.open(newTabURL, "_blank");
    };


    const getIcon = (status: number, isSelecetd: boolean) =>
        L.divIcon({
            html: `
        <div style="
          color: ${status === 1 ? "#2AB75A" : "red"};
          background: linear-gradient(145deg, ${status === 1 ? "#83E29C" : "#F77373"}, ${status === 1 ? "#C3F3D1" : "#FFA3A3"});
          border: 3px solid ${status === 1 ? "#2AB75A" : "#D50000"};
          box-shadow: 0px 0px 6px 0px ${status === 1 ? "#2AB75A" : "#D50000"};
          border-radius: 50%;
          width: ${isSelecetd ? zoomLevel * 7 : zoomLevel * 2}px;
          height:  ${isSelecetd ? zoomLevel * 7 : zoomLevel * 2}px;
          display: inline-flex;
        "></div>
      `,
        });

    const MyComponent = () => {
        useMapEvents({
            zoomend: () => {
                if (map) {
                    const zoomLvl = map.getZoom();
                    setZoomLevel(zoomLvl);
                }
            },
        });
        return null;
    };

    useEffect(() => {
        if (_.isEqual(prevServiceLineRef.current, selectedServiceLineLocal)) {
            return; 
        }
        prevServiceLineRef.current = selectedServiceLineLocal;

        setOpen(true)
        setSelectedTerminal(null)
        setStarlinkMapTelemetryLocal(null)

        if (!_.isEmpty(selectedServiceLineLocal) && !_.isEmpty(starlinkLocations)) {
            if (selectedServiceLineLocal?.userTerminals != '' && JSON.parse(selectedServiceLineLocal?.userTerminals)?.length > 0) {
                setSelectedTerminal(JSON.parse(selectedServiceLineLocal?.userTerminals)[0])
                getStarlinkMapTelemetry({
                    userTerminalId: JSON.parse(selectedServiceLineLocal?.userTerminals)[0]?.userTerminalId
                })
            }

            const matchingLocation = starlinkLocations?.find((location) => {
                return location.service_line_number === selectedServiceLineLocal?.serviceLineNumber;
            });

            if (matchingLocation) {
                setSelectedLocation({
                    service_line_name: matchingLocation.service_line_name,
                    service_line_number: matchingLocation.service_line_number,
                    lat: matchingLocation.lat,
                    long: matchingLocation.long,
                });
                if (map) {
                    map.setView([matchingLocation.lat, matchingLocation.long + 1.0], 6);
                }
            }
        }
    }, [selectedServiceLineLocal, starlinkLocations])

    useEffect(()=>{
        if(tableSearch && tableSearch !== ''){
        if( !_.isEmpty(starlinkLocations) && starlinkLocations?.length > 0){
            if (map) {
                map.setView([starlinkLocations[0]?.lat, starlinkLocations[0]?.long], 6);
            }
        }
        else{
            if(map){
                map.setView([35, -35], 2);
    }
        }
    } else if( tableSearch === '' && !mapClick){
        if(map){
            map.setView([35, -35], 2);
}
    }
    },[starlinkLocations])

    const handleLayerClick = () => {
        if (open) {
            if (map) {
                map.setView([35, -35], 2);
                setMapClick(null)
                setSelectedServiceLineLocal(null)
                setSelectedServiceLine(null)
            }
        } else {
            if (serviceLines && serviceLines.length > 0) {
                if (_.isEmpty(selectedServiceLineLocal)) {
                    setSelectedServiceLineLocal(serviceLines[0])
                }
                setSelectedServiceLine(serviceLines[0])
            }
        }
        setOpen(!open)
    }

    useEffect(()=>{
     if(mapClick){

     }else{
        if(map){
                    map.setView([35, -35], 2);
        }
     } 
    },[mapClick])

    const handleMarkerClick = (location: typeof starlinkLocations[0]) => {
        const matchedData = serviceLines?.find(
            (serviceLine) => serviceLine.serviceLineNumber === location.service_line_number
        );
    
        if (matchedData) {
            setOpen(true);
            setSelectedServiceLineLocal(matchedData);
            setSelectedServiceLine(matchedData);
        } else {
            let param = {
                nickname: location.service_line_name,
                serviceLineNumber: location.service_line_number,
                userTerminals: location?.user_terminals,
            }
            setOpen(true);
            setSelectedServiceLineLocal(param);
            setSelectedServiceLine(param);
        }

        setMapClick({
            nickname: location.service_line_name,
            serviceLineNumber: location.service_line_number,
            userTerminals: location?.user_terminals,
        })
    
        setSelectedLocation({
            service_line_name: location.service_line_name,
            service_line_number: location.service_line_number,
            lat: location.lat,
            long: location.long,
        });
    
        if (map) {
            map.setView([location.lat, location.long + 1.0], 6);
        }
    };    

    return (
        <>
            <MapContainer
                center={[35, -35]}
                zoom={zoomLevel}
                className="strMap-mapStyle"
                zoomControl={false}
                attributionControl={false} 
                whenCreated={(mapInstance) => setMap(mapInstance as LeafletMap)}
            >
                <TileLayer
                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url={`https://api.mapbox.com/styles/v1/${VITE_USERNAME}/${VITE_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${VITE_ACCESS_TOKEN}`}
                    maxZoom={19}
                />
                <ZoomControl position="topright" />
                <Control position="topright">
                    <div className="">
                        <ButtonGroup orientation="vertical" className="mapCustButtonsGroup">
                            <Button title="Insights" id="layersSel" onClick={() => { handleLayerClick() }}>
                            <img src={PerformanceIcon} alt="Performance Icon" />
                            </Button>
                        </ButtonGroup>
                    </div>
                </Control>

                <MyComponent />

                {starlinkLocations?.map((location, index) => (
                    <Marker
                        key={`${index}-${selectedServiceLineLocal?.serviceLineNumber}`}
                        position={[location.lat, location.long]}
                        icon={location?.service_line_number === selectedServiceLineLocal?.serviceLineNumber
                            ? getIcon(location.status, true)
                            : getIcon(location.status, false)}
                        eventHandlers={{
                            click: () => handleMarkerClick(location),
                        }}
                    >
                        <Tooltip
                            direction="top"
                            offset={[0, -zoomLevel * 2]}
                            opacity={1}
                            className="strMaps-custom-tooltip"
                            permanent={location?.service_line_number === selectedServiceLineLocal?.serviceLineNumber}
                        >
                            {location.service_line_name}
                        </Tooltip>
                    </Marker>
                ))}


                {/* {selectedLocation && ( */}
                {open && selectedServiceLineLocal && (
                    <div className="overlay-container" >
                        <button
                            className="cancel-button"
                            onClick={() => {
                                setSelectedServiceLine(null)
                                setMapClick(null)
                                setOpen(!open)
                                if (map) {
                                    map.setView([35, -35], 3);
                                }
                            }}
                        >
                            ✕
                        </button>
                        <Typography variant="h6" className="titleTypo">
                            Service Line Details
                        </Typography>
                        <Table size="small" className="strMap-sl-popup">
                            <TableBody>
                                <TableRow className="TableRowBorder">
                                    <TableCell className="contentTypo">
                                        Name:
                                    </TableCell>
                                    <TableCell className="TableCellPopUp">
                                        {selectedServiceLineLocal?.nickname}
                                    </TableCell>
                                </TableRow>
                                <TableRow className="TableRowBorder">
                                    <TableCell className="contentTypo">
                                        Number:
                                    </TableCell>
                                    <TableCell className="TableCellPopUp">
                                        {selectedServiceLineLocal?.serviceLineNumber}
                                    </TableCell>
                                </TableRow>
                                <TableRow className="TableRowBorder">
                                    <TableCell className="contentTypo">
                                        Terminal:
                                    </TableCell>
                                    <TableCell className="TableCellPopUp">
                                        <Select
                                            fullWidth
                                            size="small"
                                            value={selectedTerminal || ""}
                                            className="strMaps-custom-select strMap-sl-dropdown"
                                            onChange={(e) => handleChangeTerminal(e.target.value)}
                                            displayEmpty
                                            renderValue={(selected) =>
                                                selected
                                                    ? `...${selected?.kitSerialNumber.slice(-8)}`
                                                    : "Select Terminal"
                                            }
                                        >
                                            <MenuItem value="" disabled>
                                                Select Terminal
                                            </MenuItem>
                                            {selectedServiceLineLocal?.userTerminals !== "" &&
                                                JSON.parse(selectedServiceLineLocal?.userTerminals)?.map((terminal) => (
                                                    <MenuItem key={terminal.userTerminalId} value={terminal}>
                                                        {terminal.kitSerialNumber}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </TableCell>
                                </TableRow>

                                {starlinkMapTelemetryLocal && starlinkMapTelemetryLocal.length > 0 && <>
                                    <TableRow className="TableRowBorder">
                                        <TableCell className="contentTypo">
                                            UL Tput:
                                        </TableCell>
                                        <TableCell className="TableCellPopUp">
                                            {starlinkMapTelemetryLocal[0]?.uplink_throughput?.toFixed(2)} Mbps
                                        </TableCell>
                                    </TableRow>

                                    <TableRow className="TableRowBorder">
                                        <TableCell className="contentTypo">
                                            DL Tput:
                                        </TableCell>
                                        <TableCell className="TableCellPopUp">
                                            {starlinkMapTelemetryLocal[0]?.downlink_throughput?.toFixed(2)} Mbps
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="TableRowBorder">
                                        <TableCell className="contentTypo">
                                            Signal Quality:
                                        </TableCell>
                                        <TableCell className="TableCellPopUp">
                                            {starlinkMapTelemetryLocal[0]?.signal_quality?.toFixed(2)} %
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="TableRowBorder">
                                        <TableCell className="contentTypo">
                                            Obstuction %:
                                        </TableCell>
                                        <TableCell className="TableCellPopUp">
                                            {starlinkMapTelemetryLocal[0]?.obstruction_percent_time?.toFixed(2)} %
                                        </TableCell>
                                    </TableRow>

                                    <TableRow className="TableRowBorder">
                                        <TableCell className="contentTypo">
                                            Ping Drop Rate:
                                        </TableCell>
                                        <TableCell className="TableCellPopUp">
                                            {starlinkMapTelemetryLocal[0]?.ping_drop_rate_avg?.toFixed(2)} %
                                        </TableCell>
                                    </TableRow>

                                    <TableRow className="TableRowBorder">
                                        <TableCell className="contentTypo">
                                            Ping Latency:
                                        </TableCell>
                                        <TableCell className="TableCellPopUp">
                                            {starlinkMapTelemetryLocal[0]?.ping_latency_ms_avg?.toFixed(2)}
                                        </TableCell>
                                    </TableRow>

                                </>}
                            </TableBody>
                        </Table>

                        <div className="d-flex str-justify">
                            <Button size="small" variant="contained" className='starlink--button_contained--primary' 
                            disabled={selectedServiceLineLocal ?  selectedServiceLineLocal?.userTerminals ? selectedServiceLineLocal?.userTerminals === "" : true : true}
                            onClick={() => {
                                handleInsightsClick(selectedServiceLineLocal)
                            }} >Insights</Button>
                            {/* <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={()=>{}} >Journey</Button> */}

                        </div>
                    </div>
                )}
            </MapContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    warnings: state.starlinkDashboard.warnings,
    starlinkMapTelemetry: state.starlinkDashboard.starlinkMapTelemetry,


});

export default withRouter(
    connect(mapStateToProps, {
        getStarlinkMapTelemetry
    })(StarlinkMap)
);