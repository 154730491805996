import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {useSelector } from 'react-redux';
import { Box, Button, Grid, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Dialog} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import { getOperationalUsageTimeseriesTable, getOperationalUsageTimeseriesTableCount } from '../../actions/Users/authenticateUsageReports';
import _ from "lodash";
import { Pagination } from '../Pagination';
import SortArrows, { SortOrder } from "../SortArrows";
import './index.css';
import * as XLSX from "xlsx";
import {GET_OPERATIONAL_USAGE_TIMESERIES_TABLE} from "../../actions/types";
import axios from "axios";
import { readableBytesAsGB } from "../../utils/util";

const OperationalTimeSeriesTable = (props) => {
    const {
        location_ids, dwn, setDwn,
        authReducer,
        getOperationalUsageTimeseriesTable,
        getOperationalUsageTimeseriesTableCount,
        newSummaryStartDate,
        newSummaryEndDate,
        reports,
        location,history
    } = props;

    const dispatch = useDispatch();
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [vessels, setVessels] = useState([]);
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
    }>({
        page: _q.has('page') ? parseInt(_q.get('page') as string) : 1,
        size: _q.has('size') ? parseInt(_q.get('size') as string) : 10
    });

    const rowsOption = [10, 15, 25, 50, 100];

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };
    const location_id = `'${location_ids}'`;
    const site_interface="";

    const downloadExcel = () => {
        if (!reports?.data?.rows){
            return;
        }
    
        const headers = [
            "Timestamp", "Sitename", "DeviceID", "Interface Name",
            "User Traffic (GB)", "Final OPS (GB)", "Total WAN (GB)", "PEP Overhead", "LAN Trafficover Tunnel TCP (GB)",
            "Tunnel Probe (GB)", "LAN Trafficover Tunnel NTCP (GB)", "Customer Traffic Native (GB)", "OPS (GB)", "NativeProbe SP (GB)",
            "Wan Usage (GB)", "DNS (GB)", "NTP (GB)", "Telemetry (GB)", "Konnect (GB)",
            "API (GB)", "SW Update (GB)", "SoftEther (GB)", "Google DNS (GB)", "EdgeSync (GB)",
            "OVPN (GB)", "Tun Probe (GB)", "WAN F Native (GB)"
        ];
        
        const worksheetData = [
            headers,
            ...reports?.data?.rows.map(row => 
                row
                    .filter(cell => !(typeof cell === 'string' && cell.startsWith("K4-")))
                    .map(cell => {
                        if (typeof cell === 'number') {
                            if (Number.isInteger(cell)) {
                                return readableBytesAsGB(cell).replace(" GB", "");
                            } else {
                                return cell.toFixed(3);
                            }
                        }
                        return cell;
                    })
            )
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Operational Reports");
        XLSX.writeFile(workbook, "OperationalTimeseriesReports.xlsx");
        dispatch({type: GET_OPERATIONAL_USAGE_TIMESERIES_TABLE, payload: {}});
    };

    const DEFAULT_PAGE_SIZE = 10;
    let page_offset: 0;    
    let page_size: 100;         

    useEffect ( () => {
        if(location_id){
            getOperationalUsageTimeseriesTable({
                vessels: location_id,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
                sort_column: "ts",      
                sort_order: "desc",     
                page_offset: 0,       
                interface_name: site_interface
            })
        }
    },[location_id, newSummaryStartDate, newSummaryEndDate])
    
    useEffect (() => {
        if(dwn && reports?.data?.rows?.length > 0){
            downloadExcel();
            setDwn(false);
        }
    },[dwn,reports])
    

    return (
        <div></div>
    )
} 

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    reports: state.authReducer.getOperationalUsageTimeseriesTable
});

export default withRouter(
    connect(mapStateToProps, {
        getOperationalUsageTimeseriesTable
    })(OperationalTimeSeriesTable)
);