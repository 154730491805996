// New Summary Dashboard

import { api } from "../../server/request";
import { handleError, handleResponse } from "../responseUtil";
import {  SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET,
    SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART,
    GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART, SET_CAPTIVE_DASHBOARD_SITES_TABLE, GET_CAPTIVE_DASHBOARD_SITES_TABLE, SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR,
    SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE, SET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE, GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE, SET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE, GET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE,
    SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, SET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR,
    GET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR, SET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST, SET_CAPTIVE_DASHBOARD_PLANS_LIST, GET_CAPTIVE_DASHBOARD_PLANS_LIST
 } from "../types";

export const getClickhouseBucketReformat = (interval) => {
    switch (interval) {
        case "5m": return '5 minute';
        case "10m": return '10 minute';
        case "15m": return '15 minute';
        case "30m": return '30 minute';
        case "1h": return '1 hour';
        case "2h": return '2 hour';
        case "3h": return '3 hour';
        case "4h": return '4 hour';
        case "6h": return '6 hour';
        case "12h": return '12 hour';
        case "1d": return '1 day';
        case "7d": return '7 day';
        default: return '1 hour';
    }
}

export const getCaptiveDashSitesSubscribersSessionsTopWidget = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, payload: true });
    const sitesSubscribersSessionsTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sitesSubscribersSessionsTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansTopWidget = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, payload: true });
    const plansTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
        }
    }
    api
        .postRequestOut(`/store/ch/query`, plansTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashUsageTopWidget = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, payload: true });
    const usageTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,

        }
    }
    api
        .postRequestOut(`/store/ch/query`, usageTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesUsageBar = (vessels, startDate, endDate, pieLimit, page, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, payload: true });
    const topSitesPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        },
        groupBy: "siteName",
        series: "quotaUsed"
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesSubscribersBar = (vessels, startDate, endDate, pieLimit, page, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, payload: true });
    const topSitesSubscribersPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        },
        groupBy: "siteName",
        series: "subscriberCount"
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSubscribersPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesSessionsBar = (vessels, startDate, endDate, pieLimit, page, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        },
        groupBy: "siteName",
        series: "numberOfSessions"
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSubscribersUsageBar = (vessels, startDate, endDate, pieLimit, page, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSubscribersSessionsBar = (vessels, startDate, endDate, pieLimit, page, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSubscribersLineChart = (vessels, startDate, endDate, interval, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, payload: true });
    const subscribersLine: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "SubscribersCount",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSessionsLineChart = (vessels, startDate, endDate, interval, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, payload: true });
    const sessionsLine: any = {
        query: 'CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "SessionsCount",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sessionsLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashUsageLineChart = (vessels, startDate, endDate, interval, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART, payload: true });
    const usageLine: any = {
        query: 'CAPTIVE_DASHBOARD_USAGE_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "quotaUsed",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, usageLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSitesTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SITES_TABLE, payload: true });
    const sitesTable: any = {
        query: 'CAPTIVE_DASHBOARD_SITES_TABLE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: (sortColumn == 'siteName' || sortColumn == 'organizationName') ? `${sortOrder} collate 'en'` : sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sitesTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SITES_TABLE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSubscribersSessionsTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE, payload: true });
    const subscribersSessionsTable: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_TABLE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersSessionsTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE))
        .catch((err) => handleError(err, dispatch));
};

// Plans
export const getCaptiveDashTopPlansUsersPie = (vessels, startDate, endDate, pieLimit, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE, payload: true });
    const topPlansUsersPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            "plan_filter": plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topPlansUsersPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_PIE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansPercentageUsageCountBar = (vessels, startDate, endDate, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            "plan_filter": plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_PERCENTAGE_USAGE_COUNT_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansUsageLineCharts = (vessels, startDate, endDate, interval, recLimit, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, payload: true });
    const plansLineChart: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "planName",
        series: "quotaUsed",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            recLimit: recLimit == 'all' ? '' : `limit ${recLimit}`,
            "plan_filter": plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, plansLineChart)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlanTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE, payload: true });
    const subscribersTable: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_TABLE_V2',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE))
        .catch((err) => handleError(err, dispatch));
};

// Filters list
export const getCaptiveDashSubscribersList = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST, payload: true });
    const subscribersList: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersList)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansList = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_LIST, payload: true });
    const subscribersList: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_LIST',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersList)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_LIST))
        .catch((err) => handleError(err, dispatch));
};