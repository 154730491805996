import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Box, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Pagination } from "../../components/Pagination";
import { getDecodeURI, getEncodedURI, redirectToTopolgyTabs, getDataFromLocalStorageParsed } from "../../utils/util";
import { getHubDataTableRowClientWise, getHubDataTableRowClientWiseCount, getHubDataClietTable, getHubDashboardClietTableData } from '../../actions/Users/authenticateHub';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import _ from "lodash";
import CircleIcon from '@mui/icons-material/Circle';
import EdgeLinkIcon from "../../asset/image/EdgeLinkIcon.svg"
import EdgeLinkIconDisabled from "../../asset/image/linkDisabled.svg";
import ConfirmDialog from "../../components/AcoordionPopUp";
import { clearData, getGeneratedLinks } from "../../components/RemoteConnections/remoteConnectionSlice";
import { displayToastError } from "../../server/request";
import ClientsData from './ClientPopup';
import { INTERVALS } from "../../constants/Constants";
import { GET_HUB_DASHBOARD_CLIENT_TABLE, GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE } from "../../actions/types";

const AgentTable = (props) => {

    const { selectedOu, startDate, endDate, getHubVesselListing, getHubDataTableRowClientWise, getHubDataTableRowClientWiseCount, hubDashboardClientWiseData, hubDashboardClientWiseLoading, hubDashboardClientWiseDataCount, location, history,
        getHubDataClietTable, getGeneratedLinks, setClientTableLoading, userTimezone, hubDataClientTable, getHubDashboardClietTableData, carrierHubDataClientTable } = props;

    let _q = getDecodeURI(location?.search);
    const [tableData, setData] = useState<any>([]);
    const [page, setPage] = useState(_q.hasOwnProperty('pageStart') ? _q.pageStart : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('pageLimit') ? _q.pageLimit : 10);
    const [count, setCount] = useState(0);
    const [selectedOrg, setSelectedOu] = useState<any>({});
    const [vessels, setVessels] = useState([]);
    const USAGE = 'clientUsage';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : USAGE);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [interval, setInterval] = useState(_q.hasOwnProperty('interval') && _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h');
    const ENTER_KEY_CODE = "Enter";
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const searchTimeoutRef = useRef<any>();
    const [wanProfilePopup, setWanProfilePopup] = useState(false);
    const [eachRow, setEachrow] = useState();
    const [clientData, setClientData] = useState([]);
    const [clientDataPopup, setClientDataPopup] = useState(false);
    const [selectedSite, setSelectedSite] = useState({});
    const [_interval, setIntervalValue] = useState(_q.hasOwnProperty('interval') ? { label: _q.interval == '15mins' ? '15m' : _q.interval, value: _q.interval } : INTERVALS[1]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!_.isEmpty(selectedOu) && selectedOu?.id) {
            setSelectedOu(selectedOu)
        }
    }, [selectedOu])

    useEffect(() => {
        if (_.isEmpty(getHubVesselListing))
            return;
        let Vessels = [];
        Vessels = getHubVesselListing?.data;
        setVessels(Vessels);
    }, [getHubVesselListing])

    useEffect(() => {
        const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
        setInterval(_interval);
        setPage(1);
        if (!_.isEmpty(vessels) && selectedOrg?.id) {
            getHubDataTableRowClientWise(selectedOrg.id, startDate, endDate, 1, limit, _interval, vessels, sort, sortOrder, searchValue);
            getHubDataTableRowClientWiseCount(selectedOrg.id, startDate, endDate, 1, limit, _interval, vessels, sort, sortOrder, searchValue);
        }
    }, [vessels, startDate, endDate])

    useEffect(() => {
        if (!_.isEmpty(hubDashboardClientWiseData)) {
            const data = hubDashboardClientWiseData.hasOwnProperty('data') ? hubDashboardClientWiseData.data : {};
            if (!_.isEmpty(data)) {
                setData(data);
            } else {
                setData([]);
            }
        }
        if (!_.isEmpty(hubDashboardClientWiseDataCount)) {
            const count = hubDashboardClientWiseDataCount.hasOwnProperty('data') ? hubDashboardClientWiseDataCount.data : 0;
            setCount(count?.rows?.[0]?.[0])
        }
    }, [hubDashboardClientWiseData, hubDashboardClientWiseDataCount])

    useEffect(() => {
        if (!_.isEmpty(hubDataClientTable)) {
            const data = hubDataClientTable.hasOwnProperty('data') ? hubDataClientTable.data : {};
            setClientData(data);
        }
        if (!_.isEmpty(carrierHubDataClientTable)) {
            const data = carrierHubDataClientTable.hasOwnProperty('data') ? carrierHubDataClientTable.data : {};
            setClientData(data);
        }
    }, [hubDataClientTable, carrierHubDataClientTable])

    const rowsOption = [5, 10, 15, 25, 50, 100];

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.pageStart : params.pageStart = value;
        setPage(value);
        doNavigate(params);
        getHubDataTableRowClientWise(selectedOrg?.id, startDate, endDate, value, limit, interval, vessels, sort, sortOrder, searchValue);
    };

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.pageStart;
        Limit == 10 ? delete params.pageLimit : params.pageLimit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getHubDataTableRowClientWise(selectedOrg?.id, startDate, endDate, Page, Limit, interval, vessels, sort, sortOrder, searchValue);
    };

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        setPage(1);
        doNavigate(params);
        getHubDataTableRowClientWise(selectedOrg?.id, startDate, endDate, 1, limit, interval, vessels, sort, sortOrder, value);
        getHubDataTableRowClientWiseCount(selectedOrg.id, startDate, endDate, 1, limit, interval, vessels, sort, sortOrder, value);
    };

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleOpenPopup = (row) => {
        setEachrow(row);
        setWanProfilePopup(true);
    }

    const handleOpenClientData = (row) => {
        const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
        if (row.service == 'AGENT') {
            getHubDashboardClietTableData(row.server_location_id, row.client_device_id, _interval)
        } else {
            getHubDataClietTable(row.server_location_id, row.client_device_id, _interval);
        }
        setClientData([]);
        setClientDataPopup(true);
        setSelectedSite({ name: row.sitename, id: row.k4Id, service: row.service, siteId: row.server_location_id, agentId: row.client_device_id });
    }

    const EDGE_PORTAL_ADDRESS = "127.0.0.1";
    const EDGE_PORTAL_PORT = 80;

    const onConnectToEdge = async (e, deviceId: any, selectedVessel) => {
        e.preventDefault()
        let resp: any = await getGeneratedLinks(selectedVessel, deviceId, { address: [EDGE_PORTAL_ADDRESS] })
        if (!_.isEmpty(resp?.result)) {
            let [edgeLink] = resp.result?.filter(rc => {
                return rc?.address === EDGE_PORTAL_ADDRESS && rc.port === EDGE_PORTAL_PORT && rc.alias.includes("Portal")
            });
            if (edgeLink && edgeLink.url) {
                let url = edgeLink.url;
                const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
                let edgeToken = loggedInUser?.data?.edge_token;
                if (!_.isEmpty(edgeToken)) {
                    let objUrl = new URL(`/login?token=${edgeToken}`, `${url}`)
                    url = objUrl?.href
                }
                window.open(url)
                clearData()
            }
        } else {
            displayToastError('No remote connection link found')
        }
    }

    const handleClosePopUp = () => {
        setWanProfilePopup(false);
    }

    const redirectToHubTopology = (row) => {
        let params = getDecodeURI(location?.search);
        params.siteId = row.server_location_id;
        params.agentId = row.client_device_id;
        delete params.pageStart;
        delete params.hubK4Ids;
        history.push({ pathname: '/fleet-dashboard/hub/topology', search: `?${getEncodedURI(params)}` });
    }

    return (
        <div className="hub-main-table-container">
            <Grid item xs={12} sm={12} md={12} lg={12} className="alignTopbarItemsCenter hub-table-search-switch">
                <Box className="searchbarBox">
                    {<TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Type something"
                        size="small"
                        value={searchValue}
                        onChange={handleOnSearchChange}
                        onKeyDown={(event) => searchBoxKeyDown(event)}
                        InputLabelProps={{ className: "serachLabel" }}
                        InputProps={{
                            className: "searchbarInputs",
                            startAdornment: (
                                <InputAdornment position="start">
                                    {!searchValue && <SearchIcon
                                        className="searchbarIconsPosition"
                                        onClick={handleOnSearchClick}
                                    />}
                                    {searchValue && <CloseIcon
                                        className="searchbarIconsPosition"
                                        onClick={handleOnSearchClear}
                                    />}
                                </InputAdornment>
                            ),
                        }}
                    />}
                </Box>
            </Grid>
            <div>
                <Pagination
                    rowsPerPageOptions={rowsOption}
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Paper className="audit-container-data hub-container-data">
                    <TableContainer className="audit-table-root hub-table-container" component={Paper} style={{ maxHeight: 'calc(100vh - 270px)', overflow: 'auto' }}>
                        <Table size="small" aria-label="collapsible sticky table" stickyHeader style={{ tableLayout: 'fixed' }}>
                            <TableHead className="audit-data-head hub-table-header">
                                <TableRow>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">DEVICE / AGENT</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">ORGANIZATION</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">Hub</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">LOCATION</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-usage">
                                        {/* <span style={{ whiteSpace: "nowrap" }} className="align-items-center"> */}
                                        <span className="hub-sorting-columns">AGENT USAGE(GB)</span>
                                        {/* <Grid className="sort-icon-margin">
                                                <img src={sort === USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USAGE, ASC_ORDER)} />
                                                <img src={sort === USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span> */}
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-mpk-status">
                                        {/* <span style={{ whiteSpace: "nowrap" }} className="align-items-center"> */}
                                        <Tooltip title='Availability'><span className="hub-sorting-columns">AVAIL(%)</span></Tooltip>
                                        {/* <Grid className="sort-icon-margin">
                                                <img src={sort === AVAILABILITY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, AVAILABILITY, ASC_ORDER)} />
                                                <img src={sort === AVAILABILITY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, AVAILABILITY, DESC_ORDER)} />
                                            </Grid>
                                        </span> */}
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-throughput">
                                        <Tooltip title={'(Mbps)'}>
                                            {/* <span className="align-items-center"> */}
                                            <span className="hub-sorting-columns">THROUGHPUT (Mbps)</span>
                                            {/* <Grid className="sort-icon-margin">
                                                    <img src={sort === THROUGHPUTY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, THROUGHPUTY, ASC_ORDER)} />
                                                    <img src={sort === THROUGHPUTY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, THROUGHPUTY, DESC_ORDER)} />
                                                </Grid>
                                            </span> */}
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell hub-width-users">
                                        {/* <span style={{ whiteSpace: "nowrap" }} className="align-items-center"> */}
                                        <span className="hub-sorting-columns">USERS</span>
                                        {/* <Grid className="sort-icon-margin">
                                                <img src={sort === CLIENTS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CLIENTS, ASC_ORDER)} />
                                                <img src={sort === CLIENTS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CLIENTS, DESC_ORDER)} />
                                            </Grid>
                                        </span> */}
                                    </TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell hub-width-internet-status">INTERNET STATUS</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell hub-width-mpk-status">MPK/VPN STATUS</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell hub-width-internet-status">TUNNELS CONNECTED</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell hub-width-portal">PORTAL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="audit-data-header audit-logs-font">
                                {!hubDashboardClientWiseLoading && tableData?.length > 0 ? tableData?.map((row, i) => (
                                    <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity hub-agent-view-table-row">
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.device_name}>
                                                {row.service == 'AGENT' ? <span onClick={() => redirectToHubTopology(row)} className='hub-redirectToOtherTabs pointerCursor'
                                                >{row.device_name ? row.device_name : '-'}</span> : <Link to={redirectToTopolgyTabs(location, "/fleet-dashboard/sites/topology", row?.k4Id, row?.sitename, {}, true, false)} className='hub-redirectToOtherTabs'
                                                >{row.device_name ? row.device_name : '-'}</Link>}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.Organization}><span>{row.Organization ? row.Organization : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.hubName}><span>{row.hubName ? row.hubName : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            {row?.location ? <Tooltip title={row.location}>
                                                <Link to={redirectToTopolgyTabs(location, "/vessel-map", row?.k4Id, row?.sitename, {}, true, false)} className={row.location ? 'hub-redirectToOtherTabs' : 'remove-cursor-locationClick'}>
                                                    {row?.location}
                                                </Link>
                                            </Tooltip> : '-'}
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            {row.clientUsage}
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            {row.service == 'AGENT' ? '-' : Math.round(row.availibility)}
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.throughput ? row.throughput.toFixed(2) : null}><span>{row.throughput ? row.throughput.toFixed(2) : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            {row.clients ? row.clients : '-'}
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <div className="each-row-content-status">
                                                {row.service == 'AGENT' ? <div>
                                                    <CircleIcon id={row.client_status == 1 ? "greenDotSymbol" : row.client_status == 0 ? "redDotSymbol" : "greyDotSymbol"} />
                                                    {row.client_status == 1 ? 'Online' : row.client_status == 0 ? 'Offline' : 'Unknown'}
                                                </div> : <div className="hub-row-internetStatus hub-redirectToOtherTabs" onClick={() => handleOpenPopup(row)}>
                                                    <CircleIcon id={row.Internet_status == 'Online' ? "greenDotSymbol" : row.Internet_status == 'Offline' ? "redDotSymbol" : "greyDotSymbol"} />
                                                    {row.Internet_status == 'Online' ? 'Online' : row.Internet_status == 'Offline' ? 'Offline' : 'Unknown'}
                                                </div>}
                                            </div>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <div className="each-row-content-status">
                                                <div className="hub-row-internetStatus hub-redirectToOtherTabs" onClick={() => handleOpenClientData(row)}>
                                                    <CircleIcon id={row.client_status == 1 ? "greenDotSymbol" : row.client_status == 0 ? "redDotSymbol" : "greyDotSymbol"} />
                                                    {row.client_status == 1 ? 'Online' : row.client_status == 0 ? 'Offline' : 'Unknown'}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <span>{row.tunnels_status ? row.tunnels_status : 0}</span>
                                        </TableCell>
                                        <TableCell align="center" className="login-left-align inter-font Table-Cell-Opacity">
                                            {row.konnect == 'STATUS_CONNECTED' ?
                                                <a className="vessel_table_cell--portal_link">
                                                    <img src={EdgeLinkIcon} title="Portal" onClick={(e) => onConnectToEdge(e, row?.parent_DevivceID, row?.k4Id)} />
                                                </a> :
                                                <a>
                                                    <img src={EdgeLinkIconDisabled} title="Portal" />
                                                </a>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={12} align='center' className="audit-logs-no-data">
                                        No Data Available
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            {wanProfilePopup && <ConfirmDialog confirmDialog={{ isOpen: wanProfilePopup }} row={eachRow} handleClosePopUp={handleClosePopUp} interval={_interval} />}
            {clientDataPopup && <ClientsData clientData={clientData} open={clientDataPopup} setOpen={setClientDataPopup} loading={setClientTableLoading} selectedSite={selectedSite} location={location} userTimezone={userTimezone} />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer?.selectedOu,
    hubDashboardClientWiseData: state.authReducer.hubDashboardClientWiseData,
    hubDashboardClientWiseLoading: state.authReducer.hubDashboardClientWiseLoading,
    hubDashboardClientWiseDataCount: state.authReducer.hubDashboardClientWiseDataCount,
    selectedOrg: state.authReducer.selectedOrg,
    startDate: state.authReducer.newSummaryStartDate,
    endDate: state.authReducer.newSummaryEndDate,
    getHubVesselListing: state.authReducer.getHubVesselListing,
    setClientTableLoading: state.authReducer.setHubDataClientTableLoading,
    userTimezone: state.authReducer.userTimezone,
    hubDataClientTable: state.authReducer.hubDataClientTable,
    carrierHubDataClientTable: state.authReducer.carrierHubDataClientTable,
});

export default withRouter(
    connect(mapStateToProps, { getHubDataTableRowClientWise, getHubDataTableRowClientWiseCount, getHubDataClietTable, getGeneratedLinks, getHubDashboardClietTableData })(AgentTable)
);