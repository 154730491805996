import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {useSelector } from 'react-redux';
import { Box, Button, Grid, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Dialog} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import { getOperationalReports } from '../../actions/Users/authenticateUsageReports';
import _ from "lodash";
import { Pagination } from '../Pagination';
import SortArrows, { SortOrder } from "../SortArrows";
import * as XLSX from "xlsx";
import {GET_OPERATIONAL_USAGE_DATA_DUMP_TABLE, SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_LOADING} from "../../actions/types";
import axios from "axios";
import { readableBytesAsGB } from "../../utils/util";

const OperationalUsageReportsDownload = (props) => {
    const {
        authReducer, reportsDownload, setReportsDownload, device, sortColumn, sortOrder, softwareVersion, total_count,
        getOperationalReports,
        newSummaryStartDate,
        newSummaryEndDate,
        reports,
        location, history
    } = props;

    const dispatch = useDispatch();
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [vessels, setVessels] = useState([]);

    const downloadExcel = () => {
        if (!reports?.data?.rows){
            return;
        }
    
        const headers = [
            "Sitename",
            "DeviceID",
            "Wan Usage (GB)",
            "Operational Usage (GB)",
            "Activity Usage (GB)",
            "Software Version"
        ];
        
        const worksheetData = [
            headers,
            ...reports?.data?.rows.map(row => 
                row
                    .filter(cell => !(typeof cell === 'string' && cell.startsWith("K4-")))
                    .map(cell => {
                        if (typeof cell === 'number') {
                            return readableBytesAsGB(cell).replace(" GB", "");
                        } else if (typeof cell === 'string' && cell.includes('~')) {
                            return cell.split('~')[0];
                        }
                        return cell;
                    })
            )
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Operational Reports");
        XLSX.writeFile(workbook, "OperationalReports.xlsx");
        dispatch({type: SET_OPERATIONAL_USAGE_DATA_DUMP_TABLE_LOADING, payload: false});
    };       

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing)){
            return;
        }
        let Vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(Vessels);
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])
    useEffect ( () => {
        if(vessels.length > 0){
            getOperationalReports({
                vessels : vessels,
                device_id: device,
                software_version: softwareVersion,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
                sort_column: sortColumn,
                sort_order: sortOrder,
                page_offset:"0",
                page_size: total_count ? total_count[0] : 0
            })
        }
    },[vessels, newSummaryStartDate, newSummaryEndDate])
    
    useEffect (() => {
        if(reportsDownload && reports?.data?.rows?.length === total_count[0] && vessels.length > 0){
            downloadExcel();
            setReportsDownload(false);
        }
    },[reportsDownload, reports])
    

    return (
        <div></div>
    )
} 

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    reports: state.authReducer.getOperationalUsageDataTable
});

export default withRouter(
    connect(mapStateToProps, {
        getOperationalReports
    })(OperationalUsageReportsDownload)
);