import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Box, Button, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { SortOrder } from "../SortArrows";
import { useEffect, useRef, useState } from "react";
import { convertDateTimeIntoTimezone, formatSize, getDecodeURI, getEncodedURI } from "../../utils/util";
import _ from "lodash";
import { getPooledUsageTable } from "./slice";
import { DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS, YYYYMMDD_HHmmss } from "../../utils/constants";
import { Pagination } from "../Pagination";
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import moment from "moment";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";

export type PooledSortBy = 'organizationName' | 'totalServiceLines' | 'totalUserTerminals' | 'totalUsage' | 'chartUsage' | 'usageLimit';

const PooledTable = (props) => {
    const { selectedOu, newSummaryStartDate, newSummaryEndDate, pooledTable, getPooledUsageTable, csvDataDownloded, csvDownloadId, userTimezone, downloadAsCSV, location, history, selectedServiceAccount } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [tableData, setTableData] = useState<any>([]);
    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const ORGANIZATION = 'organizationName';
    const SERVICElINES = 'totalServiceLines';
    const USETERMINALS = 'totalUserTerminals';
    const CHARTUSAGE = 'chartUsage';
    const USAGELIMIT = 'usageLimit';
    const TOTALUSAGE = 'totalUsage';
    const ASC_ORDER = 'asc';
    const DESC_ORDER = 'desc';
    const [tableParams, setTableParams] = useState<{
        dpId: string,
        page: number;
        size: number;
        sortBy: PooledSortBy;
        sortOrder: SortOrder;
        search: string;
        startDate: moment.Moment;
        endDate: moment.Moment;
        accounts: string;
    }>({
        dpId: selectedOu?.id,
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate,
        search: queryParams.get('search') || '',
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as PooledSortBy || TOTALUSAGE,
        sortOrder: queryParams.get('sortOrder') as SortOrder || DESC_ORDER,
        accounts: selectedServiceAccount
    });
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const ENTER_KEY_CODE = 'Enter';

    const getUsageSource = () => {
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if (hoursDifference <= 24) {
            return 'aggregation_table';
        } else {
            return 'direct_source';
        }
    }

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        setTableParams({
            ...tableParams,
            dpId: selectedOu?.id,
            startDate: newSummaryStartDate,
            endDate: newSummaryEndDate,
            accounts: selectedServiceAccount,
            page: 1
        });
    }, [selectedOu, newSummaryEndDate, newSummaryStartDate, selectedServiceAccount])

    useEffect(() => {
        if (!selectedServiceAccount || selectedServiceAccount.length === 0) return;
        const payload = {
            dpId: tableParams.dpId,
            chartStartDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
            chartEndDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
            limit: tableParams.size,
            offset: tableParams.page,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            source: getUsageSource(),
            accounts: selectedServiceAccount
        }
        getPooledUsageTable(payload);
        updateQueryParams();
    }, [tableParams])

    useEffect(() => {
        if (!_.isEmpty(pooledTable)) {
            const data = pooledTable;
            setTableData(data);
        } else {
            setTableData([]);
        }
    }, [pooledTable])

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            handleSearch(searchText);
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const Sorting = (field, order) => {
        setTableParams({
            ...tableParams,
            sortBy: field,
            sortOrder: order,
            page: 1
        });
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (tableParams.sortBy === field) {
            order = ASC_ORDER === tableParams.sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleClickOrganization9 = (row) => {
        queryParams.set('ouName', row.organizationName);
        queryParams.set('ouId', row.organizationId);
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    const convertToCsv = (data: any[][], userTimezone: string) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
        rows.forEach(row => {
            csvRows.push([row[1], row[10], row[11], formatSize(row[2]), formatSize(row[5]), formatSize(row[6]), formatSize(row[7]), formatSize(row[8]), formatSize(row[9]), formatSize(row[3])])
        })
        return [['Organization', 'Service Lines', 'User Terminals', 'Usage Limit', 'Chart Usage', 'PriorityGB', 'OptInPriorityGB', 'StandardGB', 'NonBillableGB', 'Total Usage'], ...csvRows]
    }

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'starlink-pooled-csv') {
                DownloadCSV(convertToCsv(csvDataDownloded, userTimezone)
                    , {
                        formatters: {
                            0: 'ESC-COMMA'
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'starlink-pooled-csv',
            payload: {
                dpId: selectedOu?.id,
                chartStartDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
                chartEndDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: tableData?.[0]?.totalCount,
                offset: 0,
                accounts: selectedServiceAccount?.map(acc => `'${acc}'`).join(',')
            },
            queryName: getUsageSource() == 'aggregation_table' ? 'GET_STARLINK_ORGANIZATION_USAGE_AGG_CH' : `GET_STARLINK_ORGANIZATION_USAGE_DIRECT_CH`,
        }
        downloadAsCSV(params);
    }

    const getChartUsageDate = () => {
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if (hoursDifference < 24) {
            return MMDDYYYYHMMSS_DATE_FORMAT_24_HRS;
        } else {
            return DEFAULT_DATE_FORMAT;
        }
    }

    const rowsOption = [10, 15, 25, 50, 100]

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">Usage</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                        <Grid className="margin-left-auto usage-search-border-color">
                            <Box>
                                {<TextField
                                    id="outlined-basic"
                                    classes={{ root: "input-box-uptime-tbl" }}
                                    variant="outlined"
                                    placeholder="Type something"
                                    size="small"
                                    value={searchText}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "serachBar",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchText && <SearchIcon
                                                    className="cursorPointer input-search-icons"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchText && <CloseIcon
                                                    className="cursorPointer input-search-icons"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {tableData?.[0]?.totalCount > 0 && <Grid className="downloadBtnContainer usage-downloadBtnContainer">
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={tableData?.[0]?.totalCount}
                            rowsPerPage={tableParams.size}
                            page={tableParams.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeSize}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ORGANIZATION)}>ORGANIZATION</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={tableParams.sortBy === ORGANIZATION && tableParams.sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ORGANIZATION, ASC_ORDER)} />
                                                <img src={tableParams.sortBy === ORGANIZATION && tableParams.sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, ORGANIZATION, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SERVICElINES)}>SERVICE LINES</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={tableParams.sortBy === SERVICElINES && tableParams.sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SERVICElINES, ASC_ORDER)} />
                                                <img src={tableParams.sortBy === SERVICElINES && tableParams.sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SERVICElINES, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, USETERMINALS)}>USER TERMINALS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={tableParams.sortBy === USETERMINALS && tableParams.sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USETERMINALS, ASC_ORDER)} />
                                                <img src={tableParams.sortBy === USETERMINALS && tableParams.sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USETERMINALS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    {/* <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, USAGELIMIT)}>PLAN SIZE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={tableParams.sortBy === USAGELIMIT && tableParams.sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USAGELIMIT, ASC_ORDER)} />
                                                <img src={tableParams.sortBy === USAGELIMIT && tableParams.sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USAGELIMIT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell> */}
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <Box>
                                                <span className="login -history-dp-name font-wt-900 cursor-pointer" onClick={(event) => switchSorting(event, CHARTUSAGE)}>CHART USAGE</span>
                                                <Tooltip
                                                    title={`${convertDateTimeIntoTimezone(tableParams.startDate.toString(), userTimezone, getChartUsageDate())} - ${convertDateTimeIntoTimezone(tableParams.endDate.toString(), userTimezone, getChartUsageDate())}`}
                                                >
                                                    <span className="login-history-dp-name font-wt-900 no-cursor-pointer">
                                                        ({convertDateTimeIntoTimezone(tableParams.startDate.toString(), userTimezone, DEFAULT_DATE_FORMAT)} - {convertDateTimeIntoTimezone(tableParams.endDate.toString(), userTimezone, DEFAULT_DATE_FORMAT)})
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                            <Grid className="sort-icon-margin">
                                                <img src={tableParams.sortBy === CHARTUSAGE && tableParams.sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CHARTUSAGE, ASC_ORDER)} />
                                                <img src={tableParams.sortBy === CHARTUSAGE && tableParams.sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CHARTUSAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOTALUSAGE)}>TOTAL USAGE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={tableParams.sortBy === TOTALUSAGE && tableParams.sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTALUSAGE, ASC_ORDER)} />
                                                <img src={tableParams.sortBy === TOTALUSAGE && tableParams.sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, TOTALUSAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {tableData && 0 != tableData.length ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData.map((row, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <TableCell className="login-left-align inter-font selectedClick Table-Cell-Opacity" onClick={() => handleClickOrganization9(row)}>{row?.organizationName}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row?.totalServiceLines}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row?.totalUserTerminals}</TableCell>
                                                {/* <TableCell className="login-left-align inter-font Table-Cell-Opacity">{formatSize(row?.usageLimit)}</TableCell> */}
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>
                                                    <span>{row?.chartUsage ? formatSize(row.chartUsage) : 0}</span>
                                                    <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                                        <Grid>
                                                            <Table size="small">
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Priority GB'}</TableCell>
                                                                        <TableCell>{formatSize(row?.priorityGB)}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Opt In Priority GB'}</TableCell>
                                                                        <TableCell>{formatSize(row?.optInPriorityGB)}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Standard GB'}</TableCell>
                                                                        <TableCell>{formatSize(row?.standardGB)}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Non Billable GB'}</TableCell>
                                                                        <TableCell>{formatSize(row?.nonBillableGB)}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    }>
                                                        <IconButton sx={{ padding: '5px' }}>
                                                            <CallSplitIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity pooled-table-padding-left-15px">
                                                    <Box sx={{ width: '100%', height: 10, bgcolor: row.totalUsage > row.usageLimit ? '#cc3300' : '#c2c7e3', borderRadius: 5, position: 'relative' }} >
                                                        <Box sx={{ width: `${row.totalUsage > row.usageLimit ? row.usageLimit / row.totalUsage * 100 : 100}%`, position: 'relative' }}>
                                                            <Box sx={{ width: `${row.totalUsage > row.usageLimit ? 100 : row.usagePercentage > 100 ? row.usagePercentage : 100}%`, height: 10, bgcolor: row.totalUsage > row.usageLimit ? '#ffcc00' : '#c2c7e3', borderRadius: 5, position: 'absolute' }} />
                                                            <Box sx={{ width: `${row.totalUsage > row.usageLimit ? 100 : row.usagePercentage < 100 ? row.usagePercentage : 100}%`, height: 10, bgcolor: '#1B51A7', borderRadius: 5, position: 'absolute' }} />
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        {formatSize(row.totalUsage)} / {formatSize(row.usageLimit)} (<span style={row.usagePercentage > 100 ? {color: 'red'} : {color: '#45464e'}}>{row.usagePercentage}%</span>)
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer.selectedOu,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    pooledTable: state.starlinkPooled?.pooledTable,
    csvDataDownloded: state.authReducer?.csvDataDownloded,
    csvDownloadId: state.authReducer?.csvDownloadId,
    userTimezone: state.authReducer?.userTimezone,
    selectedServiceAccount: state?.authReducer?.selectedServiceAccount
});

export default withRouter(
    connect(mapStateToProps, {
        getPooledUsageTable,
        downloadAsCSV
    })(PooledTable)
);