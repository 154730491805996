
import { Grid, Button } from "@mui/material";
import React, { useEffect, useState, Fragment } from "react";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { BC_DASHBOARD_VESSEL_SUMMARY } from "../../constants/Constants";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, queryParamK4Ids, updateTimePeriod } from "../../utils/util";
// import NotAuthorized from "../NotAuthorized";
import { getServiceFunction } from "../../utils/util";
import VesselSummary from "../../components/FleetSelection/VesselSummary";
import VesselAlertConfig from "../../components/FleetSelection/VesselAlertConfig";
import ViewVesselNotes from "../../components/ViewVesselNotes";
import EdgeEvents from "../../components/EdgeEvents";
import SiteCharts from "../../components/SiteCharts";
import HighAvailability from "../../components/HighAvailability";
import SiteLevelAlertsConfig from "../../components/SiteLevelAlertsConfig";

import { getEdgeServerStatus } from "../../actions/Users/graphQlServices";
import _ from "lodash";
import "./VesselVisualization.css";
import { RESOURCE_FIELD_VESSELNAME, RESOURCE_FIELD_ID, SITE_NOTES, EDGE_EVENTS, CHARTS, HA, REMOTE_CONNECTIONS, SIDE_MENU_ITEM_DASHBOARD, SYSTEM_HEALTH } from "../../utils/constants";
import { updatePermission } from "../../actions/Users/authenticate";
import { useHistory, useLocation } from "react-router-dom";
import RemoteConnections from "../../components/RemoteConnections"
import AlertsDashboard from "../../components/AlertsDashboard";
import AlertTablePage from "../../components/AlertTablePage";
import { getHighAvailabilityDevice } from "../../actions/Users/authenticateHighAvailability";
import HelpIcon from '@mui/icons-material/Help';
import remote_connections from "../../asset/user_guide/Remote_Connections.pdf";
import high_availability from '../../asset/user_guide/High_Availability.pdf';
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import BackArrow from "../../asset/image/arrow_back.svg";
import SystemHealth from "../../components/SystemHealth";
import TabsComponent from "../../components/NewTabs";

var Loader = require("react-loader");

const FleetVesselVisualization = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const _q = getDecodeURI(location?.search);
  const { match, authReducer, updatePermission, getHighAvailabilityDevice, isReadOnlyUser, alertsDashboard } = props;
  const setBreadcrumbInfo: any = React.useRef();
  BC_DASHBOARD_VESSEL_SUMMARY[0]["title"] = _q?.ouName ? _q.ouName : BC_DASHBOARD_VESSEL_SUMMARY[0]["title"];
  const [breadcrumb, setBreadcrumb] = useState<any[]>(BC_DASHBOARD_VESSEL_SUMMARY);
  const [selectedVessel, setSelectedVessel] = useState<any>(_q?.k4Ids ? _q.k4Ids.split(":")[0] : "");
  const [selectedSearch, setSelectedSearch] = useState<string>("");
  const [selectedVesselName, setSelectedVesselName] = useState<string>(_q?.k4Ids ? _q.k4Ids.split(":")[1] : "");
  const [APIRespones, setAPIResponse] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const [isAuthorizedToViewBetaFeature, setIsAuthorizedToViewBetaFeature] = useState<Boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(match?.params?.tab ? match.params.tab.includes("?") ? match.params.tab.split("?")[0] : match.params.tab : "topology");
  const [devicesId,setDeviceId]=useState<any>([]);
  const [showSummaryPeriod, setShowSummaryPeriod] = useState<boolean>(() => match?.params?.tab && match.params.tab !== "alerts");

  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  const not_authorized_page_title = "Dashboard";
  const not_authorized_page_message = "You are not authorised to view site summary visualisation";

  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

  //Other Chart Basic UI Options We need to move it into configuration file 
  const firstChartoptions = {
    tittleAlign: 'start',
    titlleText: 'Data Rates',
    legendPosition: 'bottom',
    lineChartTension: 0.2,
    aspectRatio: false
  }

  const ratesAndUsage = {
    uploadRate: 115.54,
    downloadRate: 15,
    uploadUsage: 10.3,
    downloadUsage: 17.4
  }

  const secondChartOptions = {
    ...firstChartoptions,
    titlleText: 'Link Status'
  }
  // Configuration ends Here

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_DASHBOARD !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_DASHBOARD };
  }

  useEffect(() => {
    setLoading(authReducer.getEdgeEventsLoading ||
      authReducer.loading ||
      authReducer.siteChartsLinkStatusLoading ||
      authReducer.siteChartsThroughputLoading ||
      authReducer.siteChartsRSSILoading ||
      authReducer.siteChartsSINRLoading ||
      authReducer.siteChartsLatencyJitterLoading ||
      authReducer.siteChartsVSATSnrLoading ||
      authReducer.siteChartsVSATyTXPowerLoading ||
      authReducer.siteChartsVSATPowerLoading ||
      authReducer.siteChartsVSATSymbolRateLoading ||
      authReducer.siteChartsSiteInterfacesLoading ||
      authReducer.siteChartsSiteDataRateLoading ||
      authReducer.edgeServerStatusLoading ||
      authReducer.vesselPlansLoading ||
      authReducer.getEdgeEventsTableLoading ||
      authReducer.getEdgeEventsTableCountLoading ||
      alertsDashboard.alertsMainInfoLoading ||
      authReducer.siteChartsSiteClientsLoading ||
      authReducer.siteChartsSiteUsageLoading ||
      authReducer.siteChartsSiteSystemUptimeLoading ||
      authReducer.searchAlertsLoading ||
      authReducer?.siteAlertsLoading || 
      authReducer?.updateSiteAlertLoading || 
      authReducer?.updateSiteAlertNotificationLoading || 
      authReducer?.deleteSiteAlertLALoading || 
      authReducer?.siteAlertLogsLoading || 
      authReducer?.updateSiteAlertStatusLoading || 
      authReducer.setSystemHealthDevicesLoading ||
      authReducer.setSystemHealthCpuChartLoading ||
      authReducer.setSystemHealthMemoryChartLoading ||
      authReducer.setSystemHealthContrackChartLoading ||
      authReducer.setSystemHealthRxDropsChartLoading ||
      authReducer.setSystemHealthSwapChartLoading ||
      authReducer.setSystemHealthTemperatureChartLoading ||
      authReducer.setSystemHealthDiskStorageChartLoading ||
      authReducer.siteChartsSiteLanStatusLoading ||
      authReducer.siteChartsSiteFlowsLoading ||
      authReducer.siteChartsSiteSpeedLoading ||
      authReducer.siteChartsSiteLatencyLoading ||
      authReducer.siteChartsSiteStatusLoading ||
      authReducer.siteChartsSiteSpeedtestLoading
    );
  }, [authReducer.getEdgeEventsLoading,
      authReducer.loading,
      authReducer.siteChartsRSSILoading,
      authReducer.siteChartsSINRLoading,
      authReducer.siteChartsLinkStatusLoading,
      authReducer.siteChartsLatencyJitterLoading,
      authReducer.siteChartsThroughputLoading,
      authReducer.siteChartsVSATSnrLoading,
      authReducer.siteChartsVSATyTXPowerLoading,
      authReducer.siteChartsVSATPowerLoading,
      authReducer.siteChartsVSATSymbolRateLoading,
      authReducer.siteChartsSiteInterfacesLoading,
      authReducer.siteChartsSiteDataRateLoading,
      authReducer.edgeServerStatusLoading,
      authReducer.searchAlertsLoading,
      authReducer.getEdgeEventsTableLoading,
      authReducer.getEdgeEventsTableCountLoading,
      alertsDashboard.alertsMainInfoLoading,
      authReducer.siteChartsSiteClientsLoading,
      authReducer.siteChartsSiteUsageLoading,
      authReducer.siteChartsSiteSystemUptimeLoading,
      authReducer.vesselPlansLoading,
      authReducer?.siteAlertsLoading, 
      authReducer?.updateSiteAlertLoading, 
      authReducer?.updateSiteAlertNotificationLoading, 
      authReducer?.deleteSiteAlertLALoading, 
      authReducer?.siteAlertLogsLoading,
      authReducer?.updateSiteAlertStatusLoading,
      authReducer.setSystemHealthDevicesLoading,
      authReducer.setSystemHealthCpuChartLoading,
      authReducer.setSystemHealthMemoryChartLoading,
      authReducer.setSystemHealthContrackChartLoading,
      authReducer.setSystemHealthRxDropsChartLoading,
      authReducer.setSystemHealthSwapChartLoading,
      authReducer.setSystemHealthTemperatureChartLoading,
      authReducer.setSystemHealthDiskStorageChartLoading,
      authReducer.siteChartsSiteLanStatusLoading,
      authReducer.siteChartsSiteFlowsLoading,
      authReducer.siteChartsSiteSpeedLoading,
      authReducer.siteChartsSiteLatencyLoading,
      authReducer.siteChartsSiteStatusLoading,
      authReducer.siteChartsSiteSpeedtestLoading
    ]);

    useEffect (()=> {
      if(!_.isEmpty(authReducer.getHighAvailabilityDevice)) {
        const arr:any =[];
        const data = authReducer.getHighAvailabilityDevice;
        data?.data?.manage_location.forEach((e)=>{
          e.products?.forEach((product)=>{
            product.devices?.forEach((id)=>{
              arr.push(id.id)
            })
          })
        })
        setDeviceId(arr)
      }
    },[authReducer.getHighAvailabilityDevice])

  // useEffect(() => {
  //   const bc = applyBreadcrumb(breadcrumb, selectedVesselName);
  //   setBreadcrumb(bc);
  //   setBreadcrumbInfo.current(bc);
  // }, [selectedVesselName, activeTab]);   

  useEffect(() => {
    let pathName = "/fleet-dashboard/sites";
    let paramsK4Ids= localStorage.getItem(queryParamK4Ids);
    let queryParams =  getDecodeURI(location?.search);

    if (queryParams?.k4Ids && paramsK4Ids){
      queryParams["k4Ids"]=paramsK4Ids;
    } else {
      delete queryParams["k4Ids"]
    }

    if(queryParams?.alert_id){
      delete queryParams.alert_id;
    }

    if(!(queryParams?.filterApplied && queryParams?.internetStatus)){
      queryParams.filterApplied = "true";
      queryParams.internetStatus = "Online,Offline";
    }

    let pURL = pathName
    if(!_.isEmpty(queryParams)){
      pURL= pURL + "?"+getEncodedURI(queryParams);
    }

    // if (pURL && breadcrumb) {
    //   if (breadcrumb[1]) {
    //     if (breadcrumb[1].hasOwnProperty("link")) {
    //       breadcrumb[1]["link"] = pURL;
    //     }
    //   }
    //   if (breadcrumb[2]) {
    //     if (breadcrumb[2].hasOwnProperty("link")) {
    //       breadcrumb[2]["link"] = pURL;
    //     }
    //   }
    //   setBreadcrumbInfo.current(breadcrumb);
    // }

    const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
    const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
    const authorizedBetaUser = checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction);
    setIsAuthorizedToViewBetaFeature(authorizedBetaUser)

    let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
    setIsAuthorizedToViewPage(authorized);

    let viewResources:any = [];
    let viewFunctionPermission = getServiceFunction(viewVesselSummaryService, viewVesselSummaryFunction);
    if (viewFunctionPermission && viewFunctionPermission.resources) {
      viewResources = viewFunctionPermission.resources;
    }
    let vesselName = "-";
    if (viewResources.length > 0) {
      let _selectedVessel = viewResources.find(viewResource => viewResource[RESOURCE_FIELD_ID] === selectedVessel)
      if (_selectedVessel) {
        vesselName = _selectedVessel["name"];
      }
    }

    if(vesselName === "-"){
      vesselName = selectedVesselName;
    }

    setSelectedVesselName(vesselName);
    if(!_.isEmpty(selectedVessel)){
      getHighAvailabilityDevice(1, selectedVessel);
    }
  }, [selectedVessel]);

  const _updatePermission = () => {
    let info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: activeTab == CHARTS || activeTab == SYSTEM_HEALTH ? false : true,
      isSingleVessel: false,
      showOu: true,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : '',
      isUnselectNeeded : true
    };
    if(activeTab == CHARTS || activeTab == SYSTEM_HEALTH){
      info.isSingleVessel = true;
      info.showOu = true;
      info.isUnselectNeeded = false;
    }
    updatePermission(info);
  }

  useEffect(() => {
    _updatePermission();
  }, [activeTab]);

  useEffect(() => {
    if(location?.pathname && !location.pathname.endsWith(activeTab)){
      let pathParts = location.pathname.split("/");
      if(pathParts.length>0){
        let tab = pathParts[pathParts.length-1];
        setActiveTab(tab);
      }
    }
  }, [location]);

  useEffect(() => {
    if(!_.isEmpty(authReducer.selectedVessel)){
      let selectedSite = authReducer.selectedVessel;
      const params = getDecodeURI(location?.search);
      if(params.hasOwnProperty("k4Ids") && selectedSite?.id && selectedSite.id !== selectedVessel){
        (activeTab == CHARTS || activeTab == SYSTEM_HEALTH) && setSelectedVessel(selectedSite.id);
        // setSelectedVesselName(selectedSite['vessel-name']);
      }
    }
  }, [authReducer.selectedVessel]);
  
  useEffect(() => {
    if(authReducer?.getVesselsListing?.locations && Array.isArray(authReducer.getVesselsListing.locations)){
      let sites = authReducer.getVesselsListing.locations;
      const params = getDecodeURI(location?.search);
      if(sites.length == 0){
        setSelectedVessel(null);
        setSelectedVesselName("-");
        delete params.k4Ids;
        history.push({ pathname: '/fleet-dashboard/sites', search: `?${getEncodedURI(params)}` });
        return;
      }
      else {
        let site = sites[0];
          if(location?.search){
            if(!(params?.k4Ids && params.k4Ids.includes(":"))){
              params.k4Ids = site.id + ':' + site.name;
              history.push({ pathname: params.pathname, search: `?${getEncodedURI(params)}` });
              setSelectedVessel(site.id);
              setSelectedVesselName(site.name)
            }
        }
      }
    }
  }, [authReducer.getVesselsListing]);

  // useEffect(() => {
  //   let vessel = authReducer?.selectedVessel;
  //   if (vessel) {
  //     setSelectedVessel(vessel[RESOURCE_FIELD_ID]);
  //     setSelectedVesselName(vessel[RESOURCE_FIELD_VESSELNAME]);
  //     breadcrumb[3] = { title: vessel[RESOURCE_FIELD_VESSELNAME] };
  //     let tab = match?.params?.tab ? match.params.tab : "topology";
  //     if ('topology' === tab) {
  //       breadcrumb[4] = { title: 'Topology' };
  //     } else {
  //       breadcrumb[4] = { title: 'Alert Configuration' };
  //     }
  //     setBreadcrumbInfo.current(breadcrumb);
  //   }
  // }, [authReducer.selectedVessel]);

  // const applyBreadcrumb = (bc: any, vesselName) => {
  //   let bcObj = bc;
  //   if (vesselName) {
  //     let vName = { title: vesselName }
  //     bcObj[3] = vName;
  //     let tab = match?.params?.tab ? match.params.tab : "topology";
  //     if ('topology' === tab) {
  //       bcObj[4] = { title: 'Topology' };
  //     } 
  //     else  if (SITE_NOTES === tab) {
  //       bcObj[4] = { title: 'Notes' };
  //     }
  //     else if(EDGE_EVENTS == tab){
  //       bcObj[4] = { title: 'Events'}
  //     }
  //     else if (CHARTS === tab){
  //       bcObj[4] = { title: 'SDWAN'}
  //     }
  //     else if(HA === tab){
  //       bcObj[4] = {title: 'HA'}
  //     }
  //     else if (REMOTE_CONNECTIONS === tab){
  //       bcObj[4] = { title: 'Remote Connections'}
  //     }
  //     else if ("internalAlerts" === tab){
  //       bcObj[4] = { title: 'Alerts - Config'}
  //     }
  //     else
  //     {
  //       bcObj[4] = { title: 'Alerts' };
  //     }
  //     return bcObj;
  //   } else {
  //     return bcObj;
  //   }
  // };

  const updateVesselName = (vesselName) => {
    setSelectedVesselName(vesselName)
    // const bc = applyBreadcrumb(breadcrumb, vesselName);
    // setBreadcrumb(bc);
    // setBreadcrumbInfo.current(bc);
    // history.go(0);
  }

  useEffect(() => {
    let params: any = getDecodeURI(location?.search);
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }, [])

  const doNavigateToTab = (path, tabName) => {
    let params: any = getDecodeURI(location?.search);
    if(path.endsWith("alerts") && params?.alert_id){
      delete params.alert_id;
    }

    if(tabName == 'topology') {
      getHighAvailabilityDevice(1, selectedVessel)
    }

    let p = {};
    p["k4Ids"] = params.k4Ids
    if(params.ouName){
      p["ouName"] = params.ouName
    }
    if(params.ouId){
      p["ouId"] = params.ouId
    }
    if(params.sideNav){
      p['sideNav'] = params.sideNav
    }
    if(params.interval) {
      p['interval'] = params.interval
    }
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    p['startDate'] = startDate;
    p['endDate'] = endDate;
    if(tabName == 'alerts') {
      p['status'] = 'isCurrentAlert';
    }
    params = p

    history.push({ pathname: path, search: `?${getEncodedURI(params)}` });
    setActiveTab(tabName);
  }

  const doNavigateToDashboard = (path) => {
    let params: any = getDecodeURI(location?.search);
    if(path.endsWith("alerts") && params?.alert_id){
      delete params.alert_id;
    }
    delete params.page;
    delete params.filterApplied;
    delete params.internetStatus;
    history.push({ pathname: path, search: `?${getEncodedURI(params)}` });
  }

  const gotoDashboard = () => {
    doNavigateToDashboard(`/fleet-dashboard/sites`);
  }

  const getPageInfo = (tab) => {
    switch(tab) {
      case 'topology' : {
        return 'topology';
      } case 'alerts': {
        return 'alerts-1';
      } case 'internalAlerts': {
        return 'internal-alerts'
      } case 'alertsConfiguration': {
        return 'alerts-config-site '
      } case 'SDWAN' : {
        return 'sdwan'
      } case 'remote-connections' : {
        return 'remote-connections-user-guide'
      } case 'highAvailability' : {
        return 'high-availability-configuration-user-guide'
      }
    }
  }

  const tabs = [
    { tabName: 'Topology', tabValue: 'topology', pathName: '/fleet-dashboard/sites/topology' },
    { tabName: 'Alerts', tabValue: 'alerts', pathName: '/fleet-dashboard/sites/alerts' },
    { tabName: 'Alerts - Config', tabValue: 'alertsConfiguration', pathName: '/fleet-dashboard/sites/alertsConfiguration', hideTab: !isReadOnlyUser ? 'No' : 'Yes' },
    { tabName: 'Notes', tabValue: 'siteNotes', pathName: '/fleet-dashboard/sites/siteNotes', hideTab: !isReadOnlyUser ? 'No' : 'Yes' },
    { tabName: 'Events', tabValue: 'events', pathName: '/fleet-dashboard/sites/events' },
    { tabName: 'SDWAN', tabValue: 'SDWAN', pathName: '/fleet-dashboard/sites/SDWAN' },
    { tabName: 'Remote Connections', tabValue: 'remote-connections', pathName: '/fleet-dashboard/sites/remote-connections', hideTab: !isReadOnlyUser ? 'No' : 'Yes' },
    { tabName: 'System Health', tabValue: 'system_health', pathName: '/fleet-dashboard/sites/system_health' },
    { tabName: 'Internal Alerts *', tabValue: 'internalAlerts', pathName: '/fleet-dashboard/sites/internalAlerts', hideTab: !isReadOnlyUser && localStorage.getItem("isBetaUser") === "true" ? 'No' : 'Yes' },
    { tabName: 'HA', tabValue: 'highAvailability', pathName: '/fleet-dashboard/sites/highAvailability', hideTab: devicesId.length > 1  && !isReadOnlyUser ? 'No' : 'Yes' },
  ]

  return (
    <div className="Form">
      {/* <BreadCrumbs breadcrumbinfo={breadcrumb} setBreadcrumbInfo={setBreadcrumbInfo} loading={loading}></BreadCrumbs> */}
      <CommonFilters loading={loading || authReducer.inventoryListingLoading || authReducer?.userPreferencesLoading || authReducer?.applyTrafficPolicyLoading || authReducer?.applyFirewallRuleLoading || authReducer?.applyWanProfileLoading || authReducer?.applyHubIPConfigLoading || authReducer?.trafficPolicyLogListLoading || authReducer?.copyQuotaConfigLoading}  showWantType={false} showSummaryPeriod={ EDGE_EVENTS === activeTab || CHARTS === activeTab || (activeTab === "alerts" && showSummaryPeriod) || HA == activeTab || SYSTEM_HEALTH == activeTab ? true : false} disableOu={CHARTS === activeTab || SYSTEM_HEALTH == activeTab ? false : true} disableSite={CHARTS === activeTab || SYSTEM_HEALTH == activeTab ? false : true}
        userGuide={[activeTab==SITE_NOTES || activeTab==EDGE_EVENTS || activeTab==SYSTEM_HEALTH ? false : true, getPageInfo(activeTab)]}></CommonFilters>
      <TabsComponent tabs={tabs} handleChangeTab={doNavigateToTab} activeTab={activeTab} backButtonNeeded={true} goBack={gotoDashboard} />
      <Grid className="topologyConatiner">
        {isAuthorizedToViewPage ? (
            <div className={activeTab === HA ? "ha-scroll tab-container" : activeTab === EDGE_EVENTS ? "tab-container edge-evt-scroll" : "tab-container "}>
              {
              !_.isEmpty(selectedVessel) ?
              "topology" === activeTab ? (
                <Fragment key="topology">
                  <VesselSummary selectedVessel={selectedVessel} selectedVesselName={selectedVesselName} setSelectedSearch={setSelectedSearch} updateVesselName={updateVesselName} />
                </Fragment>
              ) : "alerts" === activeTab ? (
                <Fragment key="alerts">
                  <AlertTablePage selectedVessel={selectedVessel} selectedVesselName={selectedVesselName} setSelectedSearch={setSelectedSearch} showSummaryPeriod={ setShowSummaryPeriod} tab='alerts'/>
                </Fragment>
              ) : "internalAlerts" === activeTab ? (
                <Fragment key="internalAlerts">
                  <AlertsDashboard siteId={selectedVessel} siteName={selectedVesselName}/>
                </Fragment>
              ) : "alertsConfiguration" === activeTab ? (
                <Fragment key="alertsConfiguration">
                  <SiteLevelAlertsConfig siteId={selectedVessel} siteName={selectedVesselName}/>
                </Fragment>
              ) : SITE_NOTES === activeTab ? (
                <Fragment key={SITE_NOTES}>
                    <ViewVesselNotes selectedVessel={selectedVessel} selectedVesselName={selectedVesselName}/>
                </Fragment>
              ) : EDGE_EVENTS === activeTab ? (
                <Fragment key={EDGE_EVENTS}>
                  <EdgeEvents selectedVessel={selectedVessel} selectedVesselName={selectedVesselName}></EdgeEvents>
                </Fragment>
              ) : CHARTS === activeTab ? (
                <Fragment key={CHARTS}>
                  <SiteCharts selectedVessel={selectedVessel} selectedVesselName={selectedVesselName} setSelectedSearch={setSelectedSearch}/>
                </Fragment>
              ) : REMOTE_CONNECTIONS === activeTab ? (
                <Fragment key={REMOTE_CONNECTIONS}>
                  <RemoteConnections selectedSite={selectedVessel} selectedSiteName={selectedVesselName} />
                </Fragment>
              ) : 
              HA === activeTab ? (
                <Fragment key={HA}>
                   <HighAvailability selectedVessel={selectedVessel} />
                </Fragment>
              ) :
              SYSTEM_HEALTH === activeTab ? (
                <Fragment key={SYSTEM_HEALTH}>
                   <SystemHealth siteId={selectedVessel} />
                </Fragment>
              ) 
              :
                null
              :null
              }
            </div>) : null
        }
      </Grid>
    </div >
  )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  alertsDashboard: state.alertsDashboard,
  errorReducer: state.errorReducer,
  isReadOnlyUser: state.authReducer?.isReadOnlyUser
});

export default withRouter(
  connect(mapStateToProps, {
    getEdgeServerStatus,
    updatePermission,
    getHighAvailabilityDevice
  })(FleetVesselVisualization)
);
